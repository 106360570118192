import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CoffeeMachine from '../assets/images/coffee_machine.svg';
import CreateBookingForm from '../components/bookings/CreateBookingForm';
import { useGetSchool } from '../hooks/contacts/useContactsQuery';
import { useSchoolQuery } from '../hooks/schools/useSchoolsQuery';
import Loading from '../components/Loading';

const CreateBooking = () => {
  const classes = useStyles();
  const school = useGetSchool();

  // Get the school details
  const { data: schoolDetails, isLoading } = useSchoolQuery(
    school?.schoolId
  );

  // Handle loading and status checks
  if (isLoading || !schoolDetails) {
    return <Loading />;
  }

  if (schoolDetails.status === 'inactive') {
    return (
      <div className={classes.root}>
        <Typography variant="h4" gutterBottom>
          Your trial period has ended.
        </Typography>
        <Typography variant="h4" gutterBottom color="primary">
          Let's brew something special together!
        </Typography>
        <Typography variant="h6" gutterBottom>
          <Typography
            variant="h6"
            component="a"
            href="mailto:help@bustlehq.com.au?subject=Trial period ended"
            color="inherit"
            // style={{ color: '#3DC6F3', textDecoration: 'none' }}
          >
            Contact us
          </Typography>{' '}
          to keep enjoying our services and let’s make your experience
          even better.
        </Typography>
        <img
          src={CoffeeMachine}
          alt="Coffee maching"
          className={classes.img}
        />
      </div>
    );
  }

  return <CreateBookingForm />;
};

export default CreateBooking;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  img: {
    marginTop: theme.spacing(10),
    maxWidth: '100%',
  },
}));
