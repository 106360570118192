import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutline';
import RateReviewIcon from '@material-ui/icons/RateReviewOutlined';
import StarIcon from '@material-ui/icons/StarBorder';
import { loginRequest } from '../../authConfig';
import { useMsal } from '@azure/msal-react';
import BustleMark from '../../assets/images/bustle_mark.svg';
import HeroImg from '../../assets/images/hero.png';
import SvgIcon from '@material-ui/core/SvgIcon';
import CoffeeSmall from '../../assets/images/coffee_small_optimised.svg';
import CoffeeMedium from '../../assets/images/coffee_medium_optimised.svg';
import CoffeeLarge from '../../assets/images/coffee_large_optimised.svg';

// TODO - Sign up button
// TODO - Book a trial button

function Copyright() {
  return (
    <>
      <Typography
        variant="body1"
        style={{ color: 'rgba(255,2552,255,0.7)' }}
        align="center"
        gutterBottom
      >
        {'Copyright © '}
        BustleHq {new Date().getFullYear()}
      </Typography>
      <Typography
        align="center"
        style={{ color: 'rgba(255,2552,255,0.7)' }}
      >
        Need customer support?{' '}
        <Typography
          variant="body1"
          component="a"
          href="mailto:help@bustlehq.com.au?subject=Support Query"
          color="inherit"
          align="center"
          style={{ color: '#3DC6F3', textDecoration: 'none' }}
        >
          help@bustlehq.com.au
        </Typography>
      </Typography>
    </>
  );
}

function TipsIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M7,20h4c0,1.1-0.9,2-2,2S7,21.1,7,20z M5,19h8v-2H5V19z M16.5,9.5c0,3.82-2.66,5.86-3.77,6.5H5.27 C4.16,15.36,1.5,13.32,1.5,9.5C1.5,5.36,4.86,2,9,2S16.5,5.36,16.5,9.5z M14.5,9.5C14.5,6.47,12.03,4,9,4S3.5,6.47,3.5,9.5 c0,2.47,1.49,3.89,2.35,4.5h6.3C13.01,13.39,14.5,11.97,14.5,9.5z M21.37,7.37L20,8l1.37,0.63L22,10l0.63-1.37L24,8l-1.37-0.63L22,6 L21.37,7.37z M19,6l0.94-2.06L22,3l-2.06-0.94L19,0l-0.94,2.06L16,3l2.06,0.94L19,6z" />
    </SvgIcon>
  );
}

function SensorIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M7.76,16.24C6.67,15.16,6,13.66,6,12s0.67-3.16,1.76-4.24l1.42,1.42C8.45,9.9,8,10.9,8,12c0,1.1,0.45,2.1,1.17,2.83 L7.76,16.24z M16.24,16.24C17.33,15.16,18,13.66,18,12s-0.67-3.16-1.76-4.24l-1.42,1.42C15.55,9.9,16,10.9,16,12 c0,1.1-0.45,2.1-1.17,2.83L16.24,16.24z M12,10c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S13.1,10,12,10z M20,12 c0,2.21-0.9,4.21-2.35,5.65l1.42,1.42C20.88,17.26,22,14.76,22,12s-1.12-5.26-2.93-7.07l-1.42,1.42C19.1,7.79,20,9.79,20,12z M6.35,6.35L4.93,4.93C3.12,6.74,2,9.24,2,12s1.12,5.26,2.93,7.07l1.42-1.42C4.9,16.21,4,14.21,4,12S4.9,7.79,6.35,6.35z" />
    </SvgIcon>
  );
}

const tiers = [
  {
    title: 'Single Hit',
    subheader: 'For the Nimble',
    price: '800',
    image: CoffeeSmall,
    description: [
      'Up to 500 students',
      'Unlimited bookings',
      '30 Days free',
    ],
    buttonText: 'Sign up for free',
    buttonVariant: 'outlined',
  },
  {
    title: 'Double Hit',
    subheader: 'For All Rounders',
    price: '1000',
    image: CoffeeMedium,
    description: [
      'Up to 600 students',
      'Unlimited bookings',
      '30 Days free',
    ],
    buttonText: 'Get started',
    buttonVariant: 'contained',
  },
  {
    title: 'Triple Hit',
    subheader: 'Heavy Consumers',
    price: '1200',
    image: CoffeeLarge,
    description: [
      '600+ students',
      'Unlimited bookings',
      '30 Days free',
    ],
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
];

const Landing = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSmall = useMediaQuery(theme.breakpoints.down('md'));

  const handleLogin = async (instance) => {
    await instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  };

  /**
   * Renders a button which, when selected, will redirect the page to the login prompt
   */
  const SignInButton = () => {
    const { instance } = useMsal();

    return (
      <Button
        variant="contained"
        color="primary"
        className="ml-auto"
        onClick={() => handleLogin(instance)}
      >
        Log in / Sign up
      </Button>
    );
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="sticky"
        className={classes.appBar}
        elevation={0}
      >
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <div className={classes.title}>
              <Link to="/">
                <img
                  src={BustleMark}
                  alt="Bustle logo"
                  className={classes.logo}
                />
              </Link>
            </div>
            <SignInButton />
          </Toolbar>
        </Container>
      </AppBar>
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="lg">
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={matchesSmall ? 2 : 10}
            >
              <Grid item xs={12} sm={4}>
                <Typography
                  component="h1"
                  variant="h3"
                  color="textPrimary"
                  gutterBottom
                >
                  The smarter way to cover your staff
                </Typography>
                <div>
                  <Typography variant="body1" paragraph>
                    You may be a coffee person...you may be a tea
                    person... or you may prefer just plain old H20!
                    Whatever your morning tipple, you deserve to enjoy
                    it in peace! Work smarter, not harder. Bustle can
                    help avoid the morning madness and let you cover
                    staff with ease.
                  </Typography>
                  <Box display="flex" pt={2} pb={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      component="a"
                      href="mailto:hello@bustlehq.com.au?subject=Request Beta Access"
                    >
                      Request a demo
                    </Button>
                  </Box>
                  <Typography
                    variant="caption"
                    component="div"
                    style={{ lineHeight: 1.4 }}
                  >
                    Please provide your school name
                    <br /> and a contact number
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={7}>
                <img
                  src={HeroImg}
                  alt="Bustle app displayed on mobile"
                  className={classes.heroImg}
                />
              </Grid>
            </Grid>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="lg">
          {/* End hero unit */}
          <Grid container spacing={10}>
            <Grid item xs={12} sm={6}>
              <Card className={classes.card} elevation={0}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height={120}
                >
                  <TipsIcon
                    color="textPrimary"
                    style={{ fontSize: 60 }}
                  />
                </Box>
                <CardContent className={classes.cardContent}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    color="primary"
                  >
                    Work smarter,
                    <br /> not harder
                  </Typography>
                  <Typography>
                    Bustle is designed around making your life easier,
                    helping you work smarter, not harder. We know how
                    busy school life gets. We've been there!
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card className={classes.card} elevation={0}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height={120}
                >
                  <SensorIcon
                    color="textPrimary"
                    style={{ fontSize: 60 }}
                  />
                </Box>
                <CardContent className={classes.cardContent}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    color="primary"
                  >
                    Online access,
                    <br /> anywhere at any time
                  </Typography>
                  <Typography>
                    You don't have to worry about combatibility issues
                    or annoying app updates. Bustle can be accessed
                    anywhere at any time on any device. Too easy!
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card className={classes.card} elevation={0}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height={120}
                >
                  <CheckCircleIcon
                    color="textPrimary"
                    style={{ fontSize: 60 }}
                  />
                </Box>
                <CardContent className={classes.cardContent}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    color="primary"
                  >
                    The popular choice for
                    <br /> schools and CRT's
                  </Typography>
                  <Typography>
                    We know CRT's and busy schools. Bustle is a top
                    pick for casual relief teachers and daily
                    organisers. We asked what you wanted and Bustle
                    was born!
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card className={classes.card} elevation={0}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height={120}
                >
                  <RateReviewIcon
                    color="textPrimary"
                    style={{ fontSize: 60 }}
                  />
                </Box>
                <CardContent className={classes.cardContent}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    color="primary"
                  >
                    Designed for schools,
                    <br /> by schools
                  </Typography>
                  <Typography>
                    We've been there. We know your pain. We've walked
                    the walk and now we hope to talk the talk! Bustle
                    has been carefully crafted for schools by schools.
                    We've listened!
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
        <Container
          maxWidth="sm"
          component="main"
          className={classes.pricingHero}
        >
          <Typography
            component="h1"
            variant="h3"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            Pricing
          </Typography>
          <Typography
            variant="h6"
            align="center"
            color="textSecondary"
            component="p"
          >
            Which caffeine hit is best for your school? Don't stress,
            we also do decaf...so please choose your preferred package
          </Typography>
        </Container>
        {/* End hero unit */}
        <Container
          maxWidth="md"
          component="main"
          className={classes.pricingContent}
        >
          <Grid container spacing={5} alignItems="flex-end">
            {tiers.map((tier) => (
              // Enterprise card is full width at sm breakpoint
              <Grid
                item
                key={tier.title}
                xs={12}
                sm={tier.title === 'Enterprise' ? 12 : 6}
                md={4}
              >
                <Card elevation={0}>
                  <div class={classes.cardImage}>
                    <img
                      src={tier.image}
                      alt="Large coffee"
                      className={classes.pricingImages}
                    />
                  </div>
                  <CardHeader
                    title={
                      <Typography
                        component="h2"
                        variant="h4"
                        align="center"
                      >
                        {tier.title}
                      </Typography>
                    }
                    subheader={
                      <Typography variant="subtitle1" align="center">
                        {tier.subheader}
                      </Typography>
                    }
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    action={
                      tier.title === 'Pro' ? <StarIcon /> : null
                    }
                    className={classes.cardHeader}
                  />
                  <CardContent>
                    <div className={classes.cardPricing}>
                      <Typography
                        component="h3"
                        variant="h3"
                        color="primary"
                      >
                        ${tier.price}
                      </Typography>
                      <Typography variant="h6" color="primary">
                        /year
                      </Typography>
                    </div>
                    <ul className={classes.cardFeatures}>
                      {tier.description.map((line, index) => (
                        <Typography
                          color={
                            index > 0 ? 'textPrimary' : 'primary'
                          }
                          component="li"
                          variant={index > 0 ? 'subtitle1' : 'h6'}
                          align="center"
                          key={line}
                        >
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>

        <div className={classes.betaContent}>
          <Typography variant="h6" align="center">
            We would love to hear from you!
          </Typography>
          <Typography
            variant="body1"
            style={{ color: 'rgba(255,255,255,0.7)' }}
            gutterBottom
            align="center"
          >
            Send us an email to request a demo and we'll be in touch{' '}
            <br /> Please provide your school name and a contact
            number.
          </Typography>
          <Box display="flex" justifyContent="center" p={2}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              component="a"
              href="mailto:hello@bustlehq.com.au?subject=Request Beta Access"
            >
              Request a demo
            </Button>
          </Box>
        </div>
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Copyright />
      </footer>
      {/* End footer */}
    </div>
  );
};

export default Landing;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.black,
  },
  title: {
    flex: 1,
  },
  logo: {
    height: '40px',
    marginTop: theme.spacing(0.75),
    [theme.breakpoints.up('sm')]: {
      height: '48px',
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1.75),
    },
  },
  heroImg: {
    maxWidth: '100%',
    display: 'block',
  },
  heroContent: {
    backgroundColor: theme.palette.primary.light,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    padding: theme.spacing(8, 0, 0),
  },
  heroCopy: {
    maxWidth: '300px',
  },
  pricingHero: {
    padding: theme.spacing(8, 0, 6),
  },
  pricingContent: {
    padding: theme.spacing(0, 0, 6),
  },
  cardGrid: {
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    background: '#f6f5f4',
    padding: '48px',
    borderRadius: '20px',
  },
  cardImage: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardContent: {
    paddingTop: 0,
    flexGrow: 1,
    textAlign: 'center',
  },
  cardHeader: {
    // backgroundColor:
    //   theme.palette.type === 'light'
    //     ? theme.palette.grey[200]
    //     : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  cardFeatures: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  betaContent: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    padding: theme.spacing(8),
  },
  footer: {
    backgroundColor: '#0F0F41',
    color: theme.palette.common.white,
    padding: theme.spacing(6),
  },
}));
