import { useQuery, useQueryClient } from 'react-query';
import { useTokenQuery } from '../useTokenQuery';

export function useBookingsQuery() {
  const tokenQuery = useTokenQuery();

  return useQuery(
    'bookings',
    () => {
      let apiUrl = process.env.REACT_APP_API_URI.trim();

      return fetch(`${apiUrl}/bookings`, {
        method: 'get',
        headers: new Headers({
          Authorization: 'Bearer ' + tokenQuery.data,
          'Content-Type': 'application/json',
        }),
      }).then((res) => res.json());
    },
    {
      enabled: !!tokenQuery.data,
    }
  );
}

export function useBookingQuery(id, enabled = true) {
  const tokenQuery = useTokenQuery();
  const queryClient = useQueryClient();

  return useQuery(
    ['booking', id],
    () => {
      let apiUrl = process.env.REACT_APP_API_URI.trim();

      return fetch(`${apiUrl}/bookings/${id}`, {
        method: 'get',
        headers: new Headers({
          Authorization: 'Bearer ' + tokenQuery.data,
          'Content-Type': 'application/json',
        }),
      }).then((res) => res.json());
    },
    {
      enabled: !!tokenQuery.data && enabled,
      useErrorBoundary: true,
      // initially populate the booking from the parent bookings query
      initialData: () =>
        queryClient
          .getQueryData('bookings')
          ?.find((booking) => booking.id === id),
      onError: (error) => alert(error),
    }
  );
}
