import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Chip from '@material-ui/core/Chip';
import { Link } from 'react-router-dom';
import ContactMoreMenu from './ContactMoreMenu';
import ContactApproval from './ContactApproval';
import UserAvatar from '../UserAvatar';
import { hasAdminRole } from '../utilities';

const ContactListItem = (props) => {
  const classes = useStyles();
  const {
    contact,
    index,
    listId,
    count,
    contactListCount,
    listEditingAllowed,
    school,
    waitingForApproval,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Added a null check for contact in the event that there is a contact that
  // does not exist but is in the list.

  return contact ? (
    <ListItem
      button
      component={Link}
      to={`../contact/${contact.id}`}
      className={classes.listItem}
    >
      {listId && (
        <ListItemIcon>
          <Typography color="textSecondary">{index + 1}</Typography>
        </ListItemIcon>
      )}
      <ListItemAvatar>
        <UserAvatar
          label={`${contact.firstName[0]}${contact.surname[0]}`}
        />
      </ListItemAvatar>
      <ListItemText
        id={contact.id}
        primary={
          <div>
            {/* TODO - add admin flag */}
            {`${contact.firstName} ${contact.surname}`}{' '}
            {hasAdminRole(contact.role) && (
              <Chip
                size="small"
                label={contact.role}
                className={classes.role}
              />
            )}{' '}
            <Typography variant="caption" color="primary">
              {contactListCount > 0 &&
                `${contactListCount} List${
                  contactListCount > 1 ? 's' : ''
                }`}
            </Typography>
          </div>
        }
        className={
          contact.status === 'Inactive' ? classes.inactive : null
        }
      />
      <ListItemSecondaryAction>
        <>
          {waitingForApproval && (
            <ContactApproval contact={contact} school={school} />
          )}
          {!waitingForApproval && (
            <>
              <IconButton
                aria-label="more"
                aria-controls="list-menu"
                aria-haspopup="true"
                onClick={(e) => handleMenuClick(e)}
                edge="end"
              >
                <MoreVertIcon />
              </IconButton>
              {anchorEl && (
                <ContactMoreMenu
                  contact={contact}
                  index={index}
                  listId={listId}
                  count={count}
                  listEditingAllowed={listEditingAllowed}
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                />
              )}
            </>
          )}
        </>
      </ListItemSecondaryAction>
    </ListItem>
  ) : null;
};

export default ContactListItem;

const useStyles = makeStyles((theme) => ({
  listItem: {
    '& .MuiListItemIcon-root': {
      minWidth: theme.spacing(3),
    },
  },
  inactive: {
    color: theme.palette.text.disabled,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  role: {
    textTransform: 'capitalize',
    cursor: 'pointer',
  },
}));
