export const initialState = {
  theme: 'defaultTheme',
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'THEME_SETTING': {
      return {
        ...state,
        theme: action.payload,
      };
    }
    case 'SNACKBAR_INFO':
      return {
        ...state,
        infoSnackbarOpen: true,
        infoSnackbarMessage: action.message,
        infoSnackbarUndo: action.undo,
      };
    case 'SNACKBAR_CLEAR':
      return {
        ...state,
        infoSnackbarOpen: false,
      };
    case 'EDIT_CONTACT_SUCCESS':
      return {
        ...state,
        contactCount: action.contacts.length,
      };
    default:
      return state;
  }
};

export default uiReducer;
