export function changeTheme(value) {
  return {
    type: 'THEME_SETTING',
    value,
  };
}

export function showInfoSnackbar(message, undo = true) {
  return {
    type: 'SNACKBAR_INFO',
    message,
    undo,
  };
}

export function clearSnackbar() {
  return {
    type: 'SNACKBAR_CLEAR',
  };
}
