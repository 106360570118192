import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import CoffeeMachine from '../assets/images/coffee_machine.svg';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SuccessDialog = (props) => {
  const classes = useStyles();
  const { open, handleClose } = props;

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar
        className={classes.appBar}
        color="transparent"
        elevation={0}
      >
        <Toolbar>
          <Typography
            variant="h6"
            className={classes.title}
          ></Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Typography variant="h4" gutterBottom>
          Sit back and relax
        </Typography>
        <Typography variant="h4" gutterBottom>
          We've got this!
        </Typography>
        <Typography variant="h6" color="primary">
          Organising some coverage now
        </Typography>
        <img
          src={CoffeeMachine}
          alt="Coffee maching"
          className={classes.img}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SuccessDialog;

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  img: {
    marginTop: theme.spacing(10),
    maxWidth: '100%',
  },
}));
