import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { defaultTheme, darkTheme } from '../theme';

const CustomThemeProvider = ({ children }) => {
  // Use theme from store
  const ui = useSelector((state) => state.ui);
  // Choose which theme to inject into layout
  const themeType =
    ui.theme === 'darkTheme' ? darkTheme : defaultTheme;

  return <ThemeProvider theme={themeType}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
