import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';

const SupportMenu = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip
        title={<Typography variant="body1">Support</Typography>}
      >
        <IconButton
          aria-controls="account-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <HelpIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menu}
      >
        <MenuItem
          button
          component="a"
          href="mailto:help@bustlehq.com.au?subject=BustleHQ support query"
        >
          <Typography variant="subtitle1">
            Get help from BustleHQ
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default SupportMenu;

const useStyles = makeStyles((theme) => ({
  menu: {
    '& .MuiPopover-paper': {
      width: '220px',
    },
  },
}));
