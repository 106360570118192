import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import ListItemText from '@material-ui/core/ListItemText';
import CalendarToday from '@material-ui/icons/CalendarToday';
import PlaceIcon from '@material-ui/icons/Place';
import EventNoteIcon from '@material-ui/icons/EventNote';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import StatusIcon from './StatusIcon';
import BookingList from './BookingList';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import UserAvatar from './UserAvatar';
import { isSameOrAfter } from './momentUtilities';

const BookingDetails = ({
  status,
  booking,
  acceptedBy,
  declinedBy,
  editState,
  bookingEdits,
  setBookingEdits,
  schoolDetails,
}) => {
  const {
    notes,
    startDate,
    endDate,
    createdBy,
    contacts,
    lastUpdated,
    teacher,
  } = booking || {};

  const classes = useStyles();
  const start = moment(startDate);
  const end = moment(endDate);
  const duration = moment.duration(end.diff(start));
  const location = useLocation();

  const handleNotesChange = (event) => {
    setBookingEdits((old) => ({ ...old, notes: event.target.value }));
  };

  if (!booking) return <h1>Not found</h1>;

  return (
    <>
      {location.pathname.includes('ack-booking') && declinedBy > -1 && (
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={
                <>
                  <Box display="flex" alignItems="center">
                    <StatusIcon status="rejected" />
                    <Box ml={1}>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                      >
                        You declined this booking
                      </Typography>
                    </Box>
                  </Box>
                </>
              }
            />
          </ListItem>
        </List>
      )}
      {location.pathname.includes('ack-booking') &&
        status === 'ACCEPTED' &&
        declinedBy === -1 && (
          <List className={classes.list}>
            <ListItem className={classes.listItem}>
              <ListItemText
                primary={
                  <>
                    <Box display="flex" alignItems="center">
                      <StatusIcon
                        status={acceptedBy ? status : 'expired'}
                      />
                      <Box ml={1}>
                        {acceptedBy ? (
                          <>
                            <Typography
                              variant="subtitle1"
                              color="textPrimary"
                              component="span"
                            >
                              Accepted
                            </Typography>
                            {isSameOrAfter(startDate) && (
                              <Typography
                                variant="subtitle1"
                                color="textPrimary"
                                component="span"
                              >
                                . {''}We'll see you soon
                              </Typography>
                            )}
                          </>
                        ) : (
                          <Typography
                            variant="subtitle1"
                            color="textPrimary"
                          >
                            Sorry, this is no longer available
                          </Typography>
                        )}
                        {status === 'CANCELLED' && (
                          <Typography>
                            {moment(lastUpdated).format('LLLL')}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </>
                }
              />
            </ListItem>
          </List>
        )}
      {status === 'CANCELLED' && (
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={
                <>
                  <Box display="flex" alignItems="center">
                    <StatusIcon status={status} />
                    <Box ml={1}>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                      >
                        Booking Cancelled
                      </Typography>
                      <Typography>
                        {moment(lastUpdated).format('LLLL')}
                      </Typography>
                    </Box>
                  </Box>
                </>
              }
            />
          </ListItem>
        </List>
      )}
      {!location.pathname.includes('ack-booking') &&
        status !== 'CANCELLED' && (
          <List className={classes.list}>
            <ListItem className={classes.listItem}>
              <ListItemText
                primary={
                  <>
                    <Box display="flex" alignItems="center">
                      <StatusIcon status={status} />
                      <Box ml={1}>
                        <Typography
                          variant="subtitle1"
                          color="textPrimary"
                        >
                          {status === 'ACCEPTED'
                            ? `Accepted by ${contacts[acceptedBy].firstName} ${contacts[acceptedBy].surname}`
                            : status === 'REJECTED'
                            ? 'No cover found'
                            : 'Organising cover'}
                        </Typography>
                        <Typography>
                          {moment(lastUpdated).format('LLLL')}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                }
              />
            </ListItem>
          </List>
        )}
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <ListItemText
            primary={
              <>
                <Box display="flex">
                  <CalendarToday color="action" />
                  <Box ml={1}>
                    <Typography variant="subtitle1" gutterBottom>
                      {moment(startDate).format('LLLL')} -{' '}
                      {duration.asDays() < 1 &&
                        moment(endDate).format('LT')}
                    </Typography>
                    {duration.asDays() >= 1 && (
                      <Typography>
                        {moment(endDate).format('LLLL')}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </>
            }
          />
        </ListItem>
        {location.pathname.includes('ack-booking') && (
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={
                <>
                  <Box display="flex">
                    <PlaceIcon color="action" />
                    <Box ml={1}>
                      <Typography variant="body1">
                        {schoolDetails?.name}
                      </Typography>
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        component="div"
                      >
                        {schoolDetails?.address}
                      </Typography>
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        component="div"
                      >
                        {schoolDetails?.phone}
                      </Typography>
                    </Box>
                  </Box>
                </>
              }
            />
          </ListItem>
        )}
        <ListItem className={classes.listItem}>
          <ListItemText
            primary={
              <>
                <Box display="flex" mt={1.5}>
                  <EventNoteIcon color="action" />
                  <Box ml={1} flex={1}>
                    {editState ? (
                      <TextField
                        autoFocus
                        id="notes"
                        multiline
                        rows={4}
                        defaultValue={bookingEdits.notes}
                        fullWidth
                        variant="outlined"
                        onChange={handleNotesChange}
                      />
                    ) : (
                      <Typography>{notes}</Typography>
                    )}
                  </Box>
                </Box>
              </>
            }
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText
            primary={
              <Box display="flex" mt={1.5} alignItems="center">
                <AccountBoxIcon color="action" />
                <Box ml={1} flex={1}>
                  <Typography>
                    Covering for{' '}
                    {!teacher || teacher === 'None' ? 'N/A' : teacher}
                  </Typography>
                </Box>
              </Box>
            }
          />
        </ListItem>
      </List>
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <ListItemText
            primary={
              <>
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  gutterBottom
                >
                  Created By
                </Typography>
                <Box display="flex" mt={1.5} alignItems="center">
                  <UserAvatar
                    label={`${createdBy.firstName[0]}${createdBy.surname[0]}`}
                  />
                  <Box ml={1}>
                    <Typography gutterBottom>
                      {createdBy.firstName} {createdBy.surname}
                    </Typography>
                  </Box>
                </Box>
              </>
            }
          />
        </ListItem>
      </List>
      {location.pathname.includes('/booking/') && (
        <BookingList booking={booking} />
      )}
    </>
  );
};

export default BookingDetails;

const useStyles = makeStyles((theme) => ({
  list: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius * 2,
    marginBottom: theme.spacing(2),
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));
