import { useMutation, useQueryClient } from 'react-query';
import { useTokenQuery } from '../useTokenQuery';

export default function useCreateContactGroup() {
  const tokenQuery = useTokenQuery();
  const queryClient = useQueryClient();

  return useMutation(
    'contactGroup_create',
    (contactGroup) => {
      let apiUrl = process.env.REACT_APP_API_URI.trim();

      return fetch(`${apiUrl}/contacts/groups`, {
        method: 'post',
        headers: new Headers({
          Authorization: 'Bearer ' + tokenQuery.data,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(contactGroup),
      }).then((res) => {
        queryClient.invalidateQueries([
          'contactGroup',
          contactGroup.id,
        ]);
        queryClient.invalidateQueries('contactGroups');
        return res.json();
      });
    },
    {
      enabled: !!tokenQuery.data,
    }
  );
}
