import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import CheckIcon from '@material-ui/icons/Check';
import useUpdateContactGroup from '../../hooks/contactGroups/useUpdateContactGroup';
import { showInfoSnackbar } from '../../redux/actions/uiActions';
import { useDispatch } from 'react-redux';

const ContactListToggle = forwardRef((props, ref) => {
  const classes = useStyles();
  const { contact, lists } = props;

  const updateContactGroup = useUpdateContactGroup();
  const dispatch = useDispatch();

  const handleEditList = (list, isOnList) => {
    let contactsArray = list.contacts ? list.contacts : [];

    if (isOnList) {
      contactsArray = contactsArray.filter(
        (c) => c.id !== contact.id
      );
    } else {
      contactsArray.push(contact);
    }

    updateContactGroup.mutate(
      {
        contactGroup: {
          ...list,
          contacts: contactsArray,
        },
      },
      {
        onSuccess: () => {
          dispatch(
            showInfoSnackbar(
              isOnList ? 'Removed from list' : 'Added to list'
            )
          );
        },
        onError: () => {
          dispatch(showInfoSnackbar('An error occurred'));
        },
      }
    );
  };

  return (
    <>
      <Typography
        component="li"
        variant="body2"
        color="textSecondary"
        className={classes.subheader}
      >
        Lists
      </Typography>
      {lists
        ?.sort((a, b) => {
          let nameA = a.text.toLowerCase();
          let nameB = b.text.toLowerCase();
          return nameA !== nameB ? (nameA < nameB ? -1 : 1) : 0;
        })
        .map((list) => {
          const isOnList = list.contacts.find(
            (c) => c.id === contact.id
          );

          return (
            <MenuItem
              onClick={() => handleEditList(list, isOnList)}
              key={list.id}
            >
              <ListItemText primary={list.text} />
              {isOnList && <CheckIcon color="primary" />}
            </MenuItem>
          );
        })}
    </>
  );
});

export default ContactListToggle;

const useStyles = makeStyles((theme) => ({
  subheader: {
    padding: theme.spacing(2, 2, 1),
  },
}));
