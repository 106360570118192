import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { Link } from 'react-router-dom';
import Loading from '../components/Loading';
import UserAvatar from '../components/UserAvatar';
import { ListItemSecondaryAction } from '@material-ui/core';
import { useSchoolsQuery } from '../hooks/schools/useSchoolsQuery';

const Schools = () => {
  const classes = useStyles();

  // Get all schools
  const { data, isLoading } = useSchoolsQuery();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box>
      <List
        subheader={
          <ListSubheader component="div" id="list-subheader">
            Schools
          </ListSubheader>
        }
      >
        {data.map((school) => (
          <div key={school?.id} className={classes.listItem}>
            <ListItem
              button
              component={Link}
              to={`../school/${school?.id}`}
            >
              <ListItemAvatar>
                <UserAvatar label={`${school?.name[0]}`} />
              </ListItemAvatar>
              <ListItemText primary={`${school?.name}`} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="edit"
                  onClick={() => console.log('edit')}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </div>
        ))}
        <ListItem
          button
          component={Link}
          to={`/create-school`}
          className={classes.listItem}
        >
          <ListItemIcon className={classes.listItemAddIcon}>
            <AddIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1" noWrap>
                Add school
              </Typography>
            }
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default Schools;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: '34em',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius * 2,
    marginBottom: theme.spacing(2),
  },
  listItem: {
    '& .MuiListItem-secondaryAction': {
      paddingRight: theme.spacing(14),
    },
    [theme.breakpoints.up('sm')]: {
      '& li .MuiListItemSecondaryAction-root': {
        display: 'none',
      },
      '& li:hover .MuiListItemSecondaryAction-root': {
        display: 'inherit',
      },
    },
  },
  listItemAddIcon: {
    marginLeft: theme.spacing(1),
    minWidth: '48px',
  },
}));
