import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '300px',
  },
});

const Loading = ({ height, size }) => {
  const classes = useStyles();
  return (
    <div
      className={classes.root}
      style={{ height: height ? `${height}px` : '300px' }}
    >
      <CircularProgress color="primary" size={size ? size : 40} />
    </div>
  );
};

export default Loading;
