import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import BookingIcon from '@material-ui/icons/Event';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import { useContactQuery } from '../hooks/contacts/useContactsQuery';
import UserAvatar from './UserAvatar';

const ProfileContent = (props) => {
  const classes = useStyles();
  const { instance, account } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const name = account.idTokenClaims.given_name || ' ';
  const surname = account.idTokenClaims.family_name || ' ';
  // Mainly for testing, probably not required in UI
  const email = account.idTokenClaims.emails[0];
  const contactQuery = useContactQuery(account.username);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = (instance) => {
    instance.logout();
  };

  return (
    <>
      <Tooltip
        title={
          <Typography variant="caption">
            Bustle account: <br /> {name} {surname}
          </Typography>
        }
      >
        <IconButton
          aria-controls="account-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <UserAvatar label={`${name[0]}${surname[0]}`} />
        </IconButton>
      </Tooltip>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menu}
      >
        <ListItem>
          <div style={{ maxWidth: '100%' }}>
            <Typography variant="body1">
              {name} {surname}
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              component="div"
              noWrap
            >
              {email}
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              component="div"
              className={classes.status}
            >
              Status: {contactQuery?.data?.status}
            </Typography>
          </div>
        </ListItem>
        <Divider component="li" className={classes.divider} />
        <MenuItem button component={Link} to="/bookings">
          <ListItemIcon>
            <BookingIcon />
          </ListItemIcon>
          <Typography variant="subtitle1">All bookings</Typography>
        </MenuItem>
        <MenuItem button component={Link} to="/settings">
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <Typography variant="subtitle1">Settings</Typography>
        </MenuItem>
        <MenuItem onClick={() => logout(instance)}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <Typography variant="subtitle1">Logout</Typography>
        </MenuItem>
        <Divider component="li" className={classes.divider} />
        <MenuItem>
          <Typography variant="body1" color="textSecondary">
            Privacy Policy
          </Typography>
        </MenuItem>
        <MenuItem>
          <Typography variant="body1" color="textSecondary">
            Terms of Service
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileContent;

const useStyles = makeStyles((theme) => ({
  menu: {
    '& .MuiPopover-paper': {
      width: '200px',
    },
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  avatar: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.dark,
  },
  status: {
    textTransform: 'capitalize',
  },
}));
