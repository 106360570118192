import { useMutation, useQueryClient } from 'react-query';
import { useTokenQuery } from '../useTokenQuery';
import { useDispatch } from 'react-redux';
import { showInfoSnackbar } from '../../redux/actions/uiActions';

export default function useUpdateBookingStatus(id) {
  const tokenQuery = useTokenQuery();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    'booking_status_update',
    (data) => {
      let apiUrl = process.env.REACT_APP_API_URI.trim();

      return fetch(`${apiUrl}/bookings/${id}/update-status`, {
        method: 'put',
        headers: new Headers({
          Authorization: 'Bearer ' + tokenQuery.data,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(data),
      }).then((res) => {
        queryClient.invalidateQueries(['booking', id]);
        queryClient.invalidateQueries('bookings');
        return res.json();
      });
    },
    {
      enabled: !!tokenQuery.data,
      onSuccess: (_, data) => {
        console.log(data);
        dispatch(
          showInfoSnackbar(
            data.value === 'CANCELLED'
              ? 'Booking Cancelled'
              : 'Booking Updated'
          )
        );
      },
      onError: () => {
        dispatch(showInfoSnackbar('An error occurred'));
      },
    }
  );
}
