import { useMutation, useQueryClient } from 'react-query'
import { useTokenQuery } from '../useTokenQuery';

export default function useDeleteContactGroup() {
  const tokenQuery = useTokenQuery();
  const queryClient = useQueryClient()

  return useMutation('contactGroup_delete', (id) => {
    let apiUrl = process.env.REACT_APP_API_URI.trim();

    return fetch(`${apiUrl}/api/contacts/groups/${id}`, {
      method: 'delete',
      headers: new Headers({
        'Authorization': 'Bearer ' + tokenQuery.data,
        'Content-Type': 'application/json'
      })
    })
      .then(res => {
        queryClient.invalidateQueries('contactGroups');
        return res.json();
      })
  }, {
    enabled: !!tokenQuery.data,
    onSuccess: () => {
      console.log('contact group deleted')
    }
  })
}