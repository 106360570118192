import { protectedResources } from '../../authConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

const getToken = (instance, account, inProgress) => {
  if (account && inProgress === 'none') {
    return instance
      .acquireTokenSilent({
        scopes: protectedResources.apiHello.scopes,
        account: account,
      })
      .then((response) => {
        // console.log("ACCESS TOKEN ", response.accessToken);
        return response.accessToken;
      })
      .catch((error) => {
        // in case if silent token acquisition fails, fallback to an interactive method
        if (error instanceof InteractionRequiredAuthError) {
          if (account && inProgress === 'none') {
            instance
              .acquireTokenRedirect({
                scopes: protectedResources.apiHello.scopes,
              })
              .then((response) => {
                console.log('2nd time lucky ACCESS TOKEN ', response);
                return response.accessToken;
              })
              .catch((error) => console.log(error));
          }
        }
      });
  }
  return new Promise();
};

export { getToken };
