import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Loading from '../components/Loading';
import BookingDetails from '../components/BookingDetails';
import {
  useParams,
  useHistory,
  useLocation,
  Redirect,
} from 'react-router-dom';
import { useBookingQuery } from '../hooks/bookings/useBookingsQuery';
import { useContactGroupsQuery } from '../hooks/contactGroups/useContactGroupsQuery';
import { useContactQuery } from '../hooks/contacts/useContactsQuery';
import { useAccount, useMsal } from '@azure/msal-react';
import useAcceptBooking from '../hooks/bookings/useAcceptBooking';
import useRejectBooking from '../hooks/bookings/useRejectBooking';
import { useQuery } from 'react-query';
import { useTokenQuery } from '../hooks/useTokenQuery';
import { isBeforeNow } from '../components/momentUtilities';
import { useSchoolQuery } from '../hooks/schools/useSchoolsQuery';

const AckBooking = () => {
  const { id } = useParams();
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const { data, isLoading, isError } = useBookingQuery(id);
  const history = useHistory();
  const location = useLocation();

  const contactQuery = useContactQuery(account.username);

  const acceptedBy =
    data &&
    data.contacts.find((contact) => contact.status === 'accepted');

  // Declined by current user? If so, indicate this in the UI
  const declinedBy =
    data &&
    data.contacts.findIndex(
      (c) =>
        c.contactId === account.username && c.status === 'rejected'
    );

  const status =
    acceptedBy && data?.status !== 'CANCELLED'
      ? 'ACCEPTED'
      : isBeforeNow(data?.startDate)
      ? 'CANCELLED'
      : data?.status;

  // If new redirect to getting started process
  if (contactQuery.isSuccess && contactQuery.data?.status === 'New') {
    return (
      <Redirect
        to={{
          pathname: '/get-started',
        }}
      />
    );
  }

  // If pending approval redirect to waiting for approval screen
  if (
    contactQuery.isSuccess &&
    contactQuery.data?.status === 'PendingApproval'
  ) {
    return (
      <Redirect
        to={{
          pathname: '/waiting-for-approval',
        }}
      />
    );
  }

  return (
    <>
      <RenderBooking
        location={location}
        history={history}
        account={account}
        data={data}
        status={status}
        acceptedBy={acceptedBy}
        isLoading={isLoading}
        isError={isError}
        declinedBy={declinedBy}
      />
      {data && status === 'CREATED' && declinedBy === -1 && (
        <BookingActions id={id} account={account} status={status} />
      )}
    </>
  );
};

function RenderBooking({
  account,
  location,
  history,
  data,
  status,
  acceptedBy,
  isLoading,
  isError,
  declinedBy,
}) {
  const classes = useStyles();
  const contactGroupsQuery = useContactGroupsQuery();
  // Get the contact group id of the booking
  const contactGroupOfBooking = contactGroupsQuery?.data?.find(
    (cg) => cg.id === data?.contactGroupId
  );
  // Get the school details
  const { data: schoolDetails } = useSchoolQuery(
    contactGroupOfBooking?.schoolId
  );

  // If the user is coming from the calendar view
  // then we need to go back to the right date
  const handleBack = (startDate) => {
    if (location.state?.message === 'calendar') {
      history.push({
        pathname: '/',
        state: {
          date: startDate,
          view: location.state.view,
          fromHistory: true,
        },
      });
    } else {
      history.goBack();
    }
  };

  return isLoading || !data ? (
    <Loading />
  ) : isError ? (
    <div>Error</div>
  ) : (
    <>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={() => handleBack(data?.startDate)}
          >
            <ArrowBackIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <section className={classes.root}>
        <Typography variant="h6" gutterBottom>
          Hi {account?.idTokenClaims.given_name} 👋
        </Typography>
        <br />
        {data && account && (
          <BookingDetails
            status={status}
            booking={data}
            acceptedBy={acceptedBy?.contactId === account.username}
            declinedBy={declinedBy}
            schoolDetails={schoolDetails}
          />
        )}
      </section>
    </>
  );
}

function BookingActions({ id, account, status }) {
  const classes = useStyles();
  const tokenQuery = useTokenQuery();

  const { mutate: acceptBooking, isLoading: acceptBookingLoading } =
    useAcceptBooking(id);
  const { mutate: rejectBooking, isLoading: rejectBookingLoading } =
    useRejectBooking(id);

  const [enabled, setEnabled] = React.useState(false);
  const [accepting, setAccepting] = React.useState(false);

  const { data, isLoading, isError } = useQuery(
    ['bookingAction', id],
    async () => {
      let apiUrl = process.env.REACT_APP_API_URI.trim();

      const res = await fetch(`${apiUrl}/bookings/${id}`, {
        method: 'get',
        headers: new Headers({
          Authorization: 'Bearer ' + tokenQuery.data,
          'Content-Type': 'application/json',
        }),
      });
      return await res.json();
    },
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
      cacheTime: 0, // disable cache
    }
  );

  React.useEffect(() => {
    if (data) {
      const contactIndex = data.contacts.findIndex(
        (c) => c.contactId === account.username
      );

      if (contactIndex === -1) {
        alert('You cannot accept this booking');
      } else if (status !== 'CREATED') {
        alert('Sorry, this booking is no longer available');
      } else if (!accepting) {
        rejectBooking();
      } else {
        acceptBooking();
      }

      // Call the createBooking mutation
    }
  }, [
    data,
    account,
    acceptBooking,
    accepting,
    status,
    rejectBooking,
  ]);

  const handleDecline = () => {
    setAccepting(false);
    setEnabled(!!tokenQuery.data);
  };

  const handleAccept = () => {
    setAccepting(true);
    setEnabled(!!tokenQuery.data);
  };

  return (
    <section className={classes.root}>
      {isLoading || acceptBookingLoading || rejectBookingLoading ? (
        <Box
          height={48}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress color="primary" size={24} />
        </Box>
      ) : isError ? (
        <div>Error</div>
      ) : (
        <Toolbar className={classes.toolbar}>
          <Button
            variant="contained"
            size="large"
            onClick={handleDecline}
          >
            No thanks
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.primaryButton}
            size="large"
            onClick={handleAccept}
          >
            {isLoading ? 'Accepting...' : 'Accept booking'}
          </Button>
        </Toolbar>
      )}
    </section>
  );
}

export default AckBooking;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: '34em',
  },
  primaryButton: {
    marginLeft: 'auto',
  },
  toolbar: {
    '&.MuiToolbar-gutters': {
      padding: 0,
    },
  },
}));
