import moment from 'moment';

export function isToday(date) {
  return moment(date).isSame(new Date(), 'day');
}

export function isBeforeToday(date) {
  return moment(date).isBefore(new Date(), 'day');
}

export function isBeforeNow(date) {
  return moment(date).isBefore();
}

export function isAfterToday(date) {
  return moment(date).isAfter(new Date(), 'day');
}

export function isSameOrAfter(date) {
  return moment(date).isSameOrAfter(new Date(), 'day');
}

export function getTimeCategory(time) {
  let timeCategory = '';
  const timeFormat = 'HH:mm:ss';

  if (
    time.isBetween(
      moment('00:00:00', timeFormat),
      moment('05:59:59', timeFormat)
    ) ||
    time.isSame(moment('00:00:00', timeFormat)) ||
    time.isSame(moment('05:59:59', timeFormat))
  ) {
    timeCategory = 'MORNING';
  } else if (
    time.isBetween(
      moment('06:00:00', timeFormat),
      moment('08:59:59', timeFormat)
    ) ||
    time.isSame(moment('06:00:00', timeFormat)) ||
    time.isSame(moment('08:59:59', timeFormat))
  ) {
    timeCategory = 'NOW';
  } else if (
    time.isBetween(
      moment('09:00:00', timeFormat),
      moment('15:59:59', timeFormat)
    ) ||
    time.isSame(moment('09:00:00', timeFormat)) ||
    time.isSame(moment('15:59:59', timeFormat))
  ) {
    timeCategory = 'AFTERNOON';
  } else if (
    time.isBetween(
      moment('16:00:00', timeFormat),
      moment('20:59:59', timeFormat)
    ) ||
    time.isSame(moment('16:00:00', timeFormat)) ||
    time.isSame(moment('20:59:59', timeFormat))
  ) {
    timeCategory = 'NOW';
  } else if (
    time.isBetween(
      moment('21:00:00', timeFormat),
      moment('23:59:59', timeFormat)
    ) ||
    time.isSame(moment('21:00:00', timeFormat)) ||
    time.isSame(moment('23:59:59', timeFormat))
  ) {
    timeCategory = 'NIGHT'; // add a day and set to 6am
  }

  return timeCategory;
}
