import { useQuery, useQueryClient } from 'react-query';
import { useTokenQuery } from '../useTokenQuery';

export function usePendingContactsForSchool(id) {
  const tokenQuery = useTokenQuery();
  const queryClient = useQueryClient();

  return useQuery(
    ['pending_contacts', id],
    () => {
      let apiUrl = process.env.REACT_APP_API_URI.trim();

      return fetch(`${apiUrl}/contacts/pending-for-school/${id}`, {
        method: 'get',
        headers: new Headers({
          Authorization: 'Bearer ' + tokenQuery.data,
          'Content-Type': 'application/json',
        }),
      }).then((res) => res.json());
    },
    {
      enabled: !!tokenQuery.data,
      staleTime: 5 * 60 * 1000, // 2 minutes
      // initially populate the booking from the parent bookings query
      onSuccess: () => {
        queryClient.invalidateQueries('contacts');
      },
    }
  );
}
