import { useMutation, useQueryClient } from 'react-query';
import { useTokenQuery } from '../useTokenQuery';
import { useDispatch } from 'react-redux';
import { showInfoSnackbar } from '../../redux/actions/uiActions';

export default function useAcceptBooking(id) {
  const tokenQuery = useTokenQuery();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation(
    'booking_accept',
    () => {
      let apiUrl = process.env.REACT_APP_API_URI.trim();

      return fetch(`${apiUrl}/bookings/${id}/accept`, {
        method: 'put',
        headers: new Headers({
          Authorization: 'Bearer ' + tokenQuery.data,
          'Content-Type': 'application/json',
        }),
      }).then((res) => {
        queryClient.invalidateQueries(['booking', id]);
        queryClient.invalidateQueries('bookings');
        return res.json();
      });
    },
    {
      enabled: !!tokenQuery.data,
      onSuccess: () => {
        dispatch(showInfoSnackbar('Booking accepted'));
      },
      onError: () => {
        dispatch(showInfoSnackbar('An error occurred'));
      },
    }
  );
}
