import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { green, orange, grey, red } from '@material-ui/core/colors';
import StatusIcon from './StatusIcon';
import moment from 'moment';

const BookingList = ({ booking }) => {
  const classes = useStyles();
  const {
    listDisplayName,
    contacts,
    pingStartDate,
    responseTimeMinutes,
  } = booking;

  return (
    <Box px={2}>
      <List
        className={classes.list}
        dense
        aria-labelledby="booking-list-subheader"
        subheader={
          <ListSubheader component="div" id="booking-list-subheader">
            {listDisplayName}
          </ListSubheader>
        }
      >
        {contacts
          .sort((a, b) => a.index - b.index)
          .map((contact, index) => {
            const status = contact.status;

            const timeMessaged = moment(new Date(pingStartDate)).add(
              index * responseTimeMinutes,
              'minutes'
            );

            const timeElapsed =
              moment().diff(pingStartDate, 'minutes') -
              index * responseTimeMinutes;

            const hasMessageBeenSent = timeElapsed > 0;

            return (
              <ListItem key={contact.contactId}>
                <ListItemText
                  primary={
                    <Box display="flex" mb={1}>
                      <Box mr={1}>
                        <StatusIcon
                          status={
                            hasMessageBeenSent || status !== 'idle'
                              ? status
                              : 'idle-upcoming'
                          }
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="body2"
                          component="div"
                        >{`${contact.firstName} ${contact.surname}`}</Typography>
                        <Typography
                          variant="caption"
                          component="div"
                          color="textSecondary"
                        >
                          {status !== 'omitted' &&
                            timeMessaged.format('LLLL')}{' '}
                          {!hasMessageBeenSent &&
                            status === 'idle' &&
                            '(Scheduled)'}
                        </Typography>
                      </Box>
                    </Box>
                  }
                />
              </ListItem>
            );
          })}
      </List>
    </Box>
  );
};

export default BookingList;

const useStyles = makeStyles((theme) => ({
  list: {
    width: '100%',
    '& .MuiListSubheader-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '& .MuiListItem-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  orange: {
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: orange[500],
  },
  green: {
    color: '#fff',
    backgroundColor: green[500],
  },
  grey: {
    color: '#fff',
    backgroundColor: grey[500],
  },
  red: {
    color: '#fff',
    backgroundColor: red[500],
  },
}));
