import { useQuery } from 'react-query';
import { getToken } from '../components/helpers/tokenAcquirer';
import { useMsal, useAccount } from '@azure/msal-react';
import { useHistory } from 'react-router-dom';

export function useTokenQuery() {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const history = useHistory();

  return useQuery(
    'token',
    () => {
      return getToken(instance, account, inProgress);
    },
    {
      onError: () => {
        console.log('redirecting to home to token query error');
        history.push('/'); // TODO - this not working - notion notes on popup blocker
      },
    }
  );
}
