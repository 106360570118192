/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import App from './components/Router';
import InfoSnackbar from './components/InfoSnackbar';
import CustomerThemeProvider from './components/CustomThemeProvider';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig.js';
import ReactGA from 'react-ga';

const msalInstance = new PublicClientApplication(msalConfig);

// Google Tag Manager init
const TRACKING_ID = 'G-P04BMSDNYZ';
ReactGA.initialize(TRACKING_ID);

// Create a client for react-query
const queryClient = new QueryClient();

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <CustomerThemeProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <QueryClientProvider client={queryClient}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <InfoSnackbar />
            <App instance={msalInstance} />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </MuiPickersUtilsProvider>
      </CustomerThemeProvider>
    </Provider>
  </MsalProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
