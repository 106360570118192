import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import ListIcon from '@material-ui/icons/List';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import ContactListToggle from './ContactListToggle';
import { Link } from 'react-router-dom';
import UserAvatar from '../UserAvatar';
import AddList from '../AddList';
import { useContactGroupsQuery } from '../../hooks/contactGroups/useContactGroupsQuery';
import { hasAdminRole } from '../utilities';

const ContactHeader = (props) => {
  const classes = useStyles();
  const { contact, school, waitingForApproval } = props;
  const [listMenuAnchorEl, setListMenuAnchorEl] = useState(null);
  const menuOpen = Boolean(listMenuAnchorEl);
  const [addListOpen, setAddListOpen] = useState(false);
  const contactGroups = useContactGroupsQuery();

  const handleListMenuClick = (event) => {
    setListMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setListMenuAnchorEl(null);
  };

  const ITEM_HEIGHT = 48;

  const handleAddListOpen = () => {
    handleMenuClose();
    setAddListOpen(true);
  };

  const contactGroupsForContact = contactGroups?.data?.filter(
    (cg) =>
      cg.contacts.find((c) => c.id === contact.id) &&
      !cg.text.includes('all-') &&
      cg.school === school?.school
  );

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mb={2}
      >
        <div className={classes.avatar}>
          <UserAvatar
            size="large"
            label={
              contact.id
                ? `${contact.firstName[0]}${contact.surname[0]}`
                : '?'
            }
          />
          {hasAdminRole(contact.role) && (
            <Chip
              size="small"
              label={contact.role}
              className={classes.role}
            />
          )}
        </div>
        {contact && (
          <>
            <Typography variant="h5" className={classes.title}>
              {contact.firstName} {contact.surname}{' '}
            </Typography>
            <Typography
              variant="subtitle2"
              className={
                contact.status === 'New' || waitingForApproval
                  ? classes.waiting
                  : contact.status === 'Active'
                  ? classes.active
                  : classes.inactive
              }
            >
              {waitingForApproval || contact.status === 'New'
                ? 'Requires approval'
                : contact.status}
            </Typography>
          </>
        )}
      </Box>
      {contact && !waitingForApproval && (
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          mb={2}
        >
          <Box mb={1} mr={1}>
            <Chip
              variant="outlined"
              label="Lists"
              icon={<ListIcon color="primary" />}
              onClick={handleListMenuClick}
            />
            <Menu
              id="list-menu-only"
              anchorEl={listMenuAnchorEl}
              open={menuOpen}
              onClose={handleMenuClose}
              className={classes.menu}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 10.5,
                  width: '30ch',
                },
              }}
            >
              <ContactListToggle
                contact={contact}
                lists={contactGroups?.data?.filter(
                  (cg) =>
                    cg.school === school?.school &&
                    !cg.text.includes('all-')
                )}
              />
              <MenuItem onClick={handleAddListOpen}>
                <ListItemIcon>
                  <AddIcon></AddIcon>
                </ListItemIcon>
                <ListItemText primary="Create List" />
              </MenuItem>
            </Menu>
          </Box>
          {contactGroupsForContact?.map((list) => {
            return (
              <Box key={list.id} mb={1} mr={1}>
                {contactGroups.isLoading ? (
                  <CircularProgress color="primary" size={24} />
                ) : !list ? (
                  <></>
                ) : (
                  <Chip
                    clickable
                    variant="outlined"
                    label={list?.text}
                    component={Link}
                    to={`/list/${list.id}`}
                  />
                )}
              </Box>
            );
          })}
        </Box>
      )}
      <AddList
        open={addListOpen}
        setOpen={setAddListOpen}
        contact={contact}
      />
    </>
  );
};

export default ContactHeader;

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginBottom: theme.spacing(2),
    position: 'relative',
  },
  role: {
    position: 'absolute',
    bottom: '10px',
    right: '-20px',
    textTransform: 'capitalize',
  },
  menu: {
    '& .MuiListItemIcon-root': {
      minWidth: '40px',
    },
  },
  active: {
    color: green[700],
    textTransform: 'capitalize',
  },
  inactive: {
    color: theme.palette.text.secondary,
    textTransform: 'capitalize',
  },
  waiting: {
    color: theme.palette.primary.main,
  },
}));
