import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import TimerIcon from '@material-ui/icons/Timer';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import TextsmsIcon from '@material-ui/icons/Textsms';
import BlockIcon from '@material-ui/icons/Block';
import { green, orange, grey, red } from '@material-ui/core/colors';

const BookingStatusIcon = ({ status }) => {
  const classes = useStyles();

  switch (status.toLowerCase()) {
    case 'created':
      return (
        <Avatar
          className={`${classes.orange} ${classes.small}`}
          component="span"
        >
          <TextsmsIcon style={{ fontSize: 14 }} />
        </Avatar>
      );
    case 'sms-sent':
      return (
        <Avatar
          className={`${classes.orange} ${classes.small}`}
          component="span"
        >
          <TextsmsIcon style={{ fontSize: 14 }} />
        </Avatar>
      );
    case 'idle':
      return (
        <Avatar
          className={`${classes.grey} ${classes.small}`}
          component="span"
        >
          <TextsmsIcon style={{ fontSize: 14 }} />
        </Avatar>
      );
    case 'idle-upcoming':
      return (
        <Avatar
          className={`${classes.grey} ${classes.small}`}
          component="span"
        >
          <TextsmsIcon style={{ fontSize: 14 }} />
        </Avatar>
      );
    case 'accepted':
      return (
        <Avatar
          className={`${classes.green} ${classes.small}`}
          component="span"
        >
          <ThumbUpIcon style={{ fontSize: 14 }} />
        </Avatar>
      );
    case 'rejected':
      return (
        <Avatar
          className={`${classes.red} ${classes.small}`}
          component="span"
        >
          <ThumbDownIcon style={{ fontSize: 14 }} />
        </Avatar>
      );
    case 'expired':
      return (
        <Avatar
          className={`${classes.grey} ${classes.small}`}
          component="span"
        >
          <TimerIcon style={{ fontSize: 14 }} />
        </Avatar>
      );
    case 'omitted':
      return (
        <Avatar
          className={`${classes.lightGrey} ${classes.small}`}
          component="span"
        >
          <BlockIcon style={{ fontSize: 14 }} />
        </Avatar>
      );
    default:
      return (
        <Avatar
          className={`${classes.grey} ${classes.small}`}
          component="span"
        >
          <EventBusyIcon style={{ fontSize: 14 }} />
        </Avatar>
      );
  }
};

export default BookingStatusIcon;

const useStyles = makeStyles((theme) => ({
  orange: {
    color: theme.palette.getContrastText(orange[800]),
    backgroundColor: orange[600],
  },
  green: {
    color: '#fff',
    backgroundColor: green[600],
  },
  grey: {
    color: '#fff',
    backgroundColor: grey[800],
  },
  lightGrey: {
    color: '#fff',
    backgroundColor: grey[400],
  },
  red: {
    color: '#fff',
    backgroundColor: red[600],
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));
