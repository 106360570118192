import { useQuery, useQueryClient } from 'react-query';
import { useTokenQuery } from '../useTokenQuery';

export function useContactGroupsQuery() {
  const tokenQuery = useTokenQuery();

  return useQuery(
    'contactGroups',
    async () => {
      let apiUrl = process.env.REACT_APP_API_URI.trim();

      return await fetch(`${apiUrl}/contacts/groups`, {
        method: 'get',
        headers: new Headers({
          Authorization: 'Bearer ' + tokenQuery.data,
          'Content-Type': 'application/json',
        }),
      }).then((res) => res.json());
    },
    {
      enabled: !!tokenQuery.data,
    }
  );
}

export function useContactGroupQuery(id) {
  const tokenQuery = useTokenQuery();
  const queryClient = useQueryClient();

  return useQuery(
    ['contactGroup', id],
    async () => {
      let apiUrl = process.env.REACT_APP_API_URI.trim();

      return await fetch(`${apiUrl}/contacts/groups/${id}`, {
        method: 'get',
        headers: new Headers({
          Authorization: 'Bearer ' + tokenQuery.data,
          'Content-Type': 'application/json',
        }),
      }).then((res) => res.json());
    },
    {
      enabled: !!tokenQuery.data,
      initialData: () =>
        queryClient
          .getQueryData('contactGroups')
          ?.find((contactGroup) => contactGroup.id === id),
    }
  );
}
