import React, { useState } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { showInfoSnackbar } from '../redux/actions/uiActions';
import { useDispatch } from 'react-redux';
import useCreateContactGroup from '../hooks/contactGroups/useCreateContactGroup';
import useUpdateContactGroup from '../hooks/contactGroups/useUpdateContactGroup';
import { useGetSchool } from '../hooks/contacts/useContactsQuery';
import { useContactQuery } from '../hooks/contacts/useContactsQuery';
import { useMsal } from '@azure/msal-react';

const AddList = (props) => {
  //   const classes = useStyles();
  const { open, setOpen, list, contact } = props;
  const [name, setName] = useState(list ? list.text : '');

  const { accounts } = useMsal();

  const dispatch = useDispatch();

  const createContactGroup = useCreateContactGroup();
  const updateContactGroup = useUpdateContactGroup();
  const school = useGetSchool();

  const userQuery = useContactQuery(accounts[0]?.username);

  const handleAddList = () => {
    const contacts = contact
      ? [contact, userQuery?.data]
      : [userQuery?.data];

    createContactGroup.mutate(
      {
        contactGroup: {
          text: name,
          contacts: contacts,
          status: 'active',
          school: school.school,
          address: null,
          endHours: '',
          endMinutes: '',
          startHours: '',
          startMinutes: '',
          teachers: null,
          phone: null,
          schoolId: school.schoolId,
        },
      },
      {
        onSuccess: () => {
          dispatch(showInfoSnackbar('List created'));
          setName('');
          handleClose();
        },
      }
    );
  };

  const handleEditList = () => {
    updateContactGroup.mutate(
      {
        contactGroup: {
          ...list,
          text: name,
        },
      },
      { onSuccess: () => dispatch(showInfoSnackbar('List updated')) }
    );

    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setName(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="add-list-dialog-title"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="add-list-dialog-title">
        {list ? `Rename` : 'Create'} list
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="listName"
          label="List name"
          type="text"
          fullWidth
          value={name}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={list ? handleEditList : handleAddList}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddList;

// const useStyles = makeStyles((theme) => ({}));
