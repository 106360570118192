import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import Loading from '../components/Loading';
import BookingDetails from '../components/BookingDetails';
import { useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useBookingQuery } from '../hooks/bookings/useBookingsQuery';
import useUpdateBookingStatus from '../hooks/bookings/useUpdateBookingStatus';
import useUpdateBookingNotes from '../hooks/bookings/useUpdateBookingNotes';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import NotFound from './404';
import { isBeforeNow } from '../components/momentUtilities';

const EditBooking = () => {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { data: booking, isLoading, isError } = useBookingQuery(id);

  const {
    mutate: updateBookingStatus,
    isSuccess: updateBookingStatusIsSuccess,
    isLoading: updateBookingStatusLoading,
  } = useUpdateBookingStatus(id);

  const {
    mutate: updateBookingNotes,
    isSuccess: updateBookingNotesIsSuccess,
    isLoading: updateBookingNotesLoading,
  } = useUpdateBookingNotes(id);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [editState, setEditState] = useState(false);
  const [bookingEdits, setBookingEdits] = useState();
  const [saveState, setSaveState] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  // Sync booking edits with booking state
  React.useEffect(() => {
    setBookingEdits(booking);
  }, [booking]);

  // Fire off the success dialog
  React.useEffect(() => {
    if (updateBookingNotesIsSuccess) {
      setEditState(false);
      setSaveState(false);
    }
  }, [
    updateBookingStatusIsSuccess,
    updateBookingNotesIsSuccess,
    dispatch,
  ]);

  // Enable save button when notes are edited
  React.useEffect(() => {
    if (booking !== bookingEdits && editState) {
      setSaveState(true);
    }
  }, [booking, bookingEdits, editState]);

  // Has the booking been accepted by someone?
  const acceptedBy =
    booking &&
    Object.keys(booking.contacts).find(
      (key) => booking.contacts[key].status === 'accepted'
    );

  // Have all the contact rejected the booking?
  const rejectedByAll =
    booking &&
    Object.keys(booking.contacts).filter(
      (key) => booking.contacts[key].status === 'rejected'
    ).length === Object.keys(booking.contacts).length;

  // Display the booking status as 'CANCELLED' if
  // past booking startDate
  const status =
    acceptedBy && booking?.status !== 'CANCELLED'
      ? 'ACCEPTED'
      : isBeforeNow(booking?.startDate)
      ? 'CANCELLED'
      : rejectedByAll
      ? 'REJECTED'
      : booking?.status;

  const handleDialogOpen = () => {
    setDialogOpen(true);
    handleClose();
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleCancelBooking = () => {
    updateBookingStatus({ value: 'CANCELLED' });
    handleDialogClose();
  };

  const handleUpdateBooking = () => {
    updateBookingNotes({ value: bookingEdits.notes });
  };

  const handleCancelUpdates = () => {
    setBookingEdits(booking);
    setEditState(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // If the user is coming from the calendar view
  // then we need to go back to the right date
  const handleBack = (startDate) => {
    if (location.state?.message === 'calendar') {
      history.push({
        pathname: '/',
        state: {
          date: startDate,
          view: location.state.view,
          fromHistory: true,
        },
      });
    } else {
      history.goBack();
    }
  };

  const handleCopyRedirect = (id) => {
    console.log(id);
    history.push({
      pathname: `/booking/copy/${id}`,
      // state: { booking },
    });
  };

  const renderBooking = (booking) => {
    return (
      <>
        <AppBar position="static" color="transparent" elevation={0}>
          <Toolbar className={classes.toolbar}>
            {editState ? (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="cancel"
                onClick={() => handleCancelUpdates()}
              >
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="back"
                onClick={() => handleBack(booking.startDate)}
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              className={classes.primaryButton}
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: 48 * 4.5,
                  width: '20ch',
                },
              }}
            >
              <MenuItem onClick={() => handleCopyRedirect(id)}>
                Copy Booking
              </MenuItem>
              <MenuItem
                onClick={handleDialogOpen}
                disabled={status === 'CANCELLED'}
              >
                Cancel Booking
              </MenuItem>
            </Menu>
            {editState ? (
              <Button
                variant="contained"
                color="primary"
                disabled={status === 'CANCELLED' || !saveState}
                size="large"
                onClick={() => handleUpdateBooking()}
              >
                Save
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                disabled={
                  status === 'CANCELLED' ||
                  (editState && !bookingEdits.notes)
                }
                size="large"
                onClick={() => setEditState(!editState)}
              >
                Edit
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <section className={classes.root}>
          {booking && (
            <BookingDetails
              booking={booking}
              acceptedBy={acceptedBy}
              status={status}
              editState={editState}
              bookingEdits={bookingEdits}
              setBookingEdits={setBookingEdits}
            />
          )}
        </section>
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Cancel this booking?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Bookings can't be re-opened and a new booking will need
              to be created if you proceed
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Back
            </Button>
            <Button
              onClick={() => {
                handleCancelBooking();
              }}
              color="primary"
              autoFocus
            >
              Cancel Booking
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  return (
    <>
      {isLoading ||
      updateBookingStatusLoading ||
      updateBookingNotesLoading ? (
        <Loading />
      ) : isError ? (
        <NotFound />
      ) : (
        renderBooking(booking)
      )}
    </>
  );
};

export default EditBooking;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: '34em',
  },
  primaryButton: {
    marginLeft: 'auto',
    marginRight: theme.spacing(2),
  },
}));
