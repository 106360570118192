import { useQuery, useQueryClient } from 'react-query';
import { useTokenQuery } from '../useTokenQuery';

export function useSchoolsQuery() {
  const tokenQuery = useTokenQuery();

  return useQuery(
    'schools',
    async () => {
      let apiUrl = process.env.REACT_APP_API_URI.trim();

      return await fetch(`${apiUrl}/schools`, {
        method: 'get',
        headers: new Headers({
          Authorization: 'Bearer ' + tokenQuery.data,
          'Content-Type': 'application/json',
        }),
      }).then((res) => res.json());
    },
    {
      enabled: !!tokenQuery.data,
    }
  );
}

export function useSchoolQuery(schoolId) {
  const tokenQuery = useTokenQuery();
  const queryClient = useQueryClient();

  // Define a custom hook that fetches school details based on the schoolId
  const fetchSchoolDetails = async (schoolId) => {
    let apiUrl = process.env.REACT_APP_API_URI.trim();

    const response = await fetch(`${apiUrl}/schools/${schoolId}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + tokenQuery.data,
        'Content-Type': 'application/json',
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch school details');
    }

    return response.json();
  };

  // Call the useQuery hook conditionally based on whether schoolId is defined
  return useQuery(
    ['school', schoolId],
    () => fetchSchoolDetails(schoolId),
    {
      enabled: !!tokenQuery.data && !!schoolId, // Only enable if token and schoolId are available
      initialData: () =>
        queryClient
          .getQueryData('schools')
          ?.find((school) => school.id === schoolId),
    }
  );
}
