import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import SchoolIcon from '@material-ui/icons/School';
import SupervisedUserIcon from '@material-ui/icons/SupervisedUserCircle';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import TodayIcon from '@material-ui/icons/Today';
import PersonIcon from '@material-ui/icons/Person';
import HomeIcon from '@material-ui/icons/Home';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link, useLocation } from 'react-router-dom';
import BustleMark from '../../assets/images/bustle_mark.svg';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import ProfileContent from '../ProfileContent';
import SupportMenu from '../SupportMenu';
import ListsMenu from '../ListsMenu';
import InviteContact from '../InviteContact';
import Landing from './Landing';
import { useContactQuery } from '../../hooks/contacts/useContactsQuery';
import { hasAdminRole } from '../utilities';

function MainLayout(props) {
  const { window, children, pageTitle } = props;
  const classes = useStyles(props)();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [inviteContactOpen, setInviteContactOpen] = useState(false);
  const location = useLocation();

  const { instance, accounts, inProgress } = useMsal();

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleContactInviteOpen = () => {
    setInviteContactOpen(true);
  };

  const userQuery = useContactQuery(accounts[0]?.username);

  const drawer = (
    <div>
      <Hidden xsDown>
        <div className={classes.toolbar}>
          <Link to="/">
            <img
              src={BustleMark}
              alt="Bustle logo"
              className={classes.logo}
            />
          </Link>
        </div>
        <Divider />
      </Hidden>
      {userQuery.isLoading ? (
        <List className={classes.menu}>
          <ListItem className={classes.loader}>
            <CircularProgress color="primary" size={24} />
          </ListItem>
        </List>
      ) : hasAdminRole(userQuery?.data?.role) &&
        userQuery?.data?.status !== 'PendingApproval' ? (
        <>
          <List className={classes.menu}>
            <ListItem className={classes.ctaItem}>
              <a href="/booking/new">
                <Fab
                  variant="extended"
                  color="primary"
                  className={classes.ctaFab}
                >
                  <AddIcon
                    className={classes.ctaIcon}
                    fontSize="large"
                  />
                  Create Booking
                </Fab>
              </a>
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/"
              selected={location.pathname.includes('bookings')}
            >
              <ListItemIcon>
                <TodayIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2" noWrap>
                    Bookings
                  </Typography>
                }
              />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/contacts"
              selected={location.pathname === '/contacts'}
            >
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2" noWrap>
                    Contacts
                  </Typography>
                }
              />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/teachers"
              selected={location.pathname === '/teachers'}
            >
              <ListItemIcon>
                <SupervisedUserIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="body2" noWrap>
                    Teachers
                  </Typography>
                }
              />
            </ListItem>

            <ListItem button onClick={handleContactInviteOpen}>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="body2" noWrap>
                    Invite Contact
                  </Typography>
                }
              />
            </ListItem>
          </List>
          <Divider />
          {userQuery?.data?.role !== 'superAdmin' && (
            <ListsMenu location={location.pathname} />
          )}
          {userQuery?.data?.role === 'superAdmin' && (
            <>
              <Divider />
              <List className={classes.menu}>
                <ListItem
                  button
                  component={Link}
                  to="/schools"
                  selected={location.pathname === '/schools'}
                >
                  <ListItemIcon>
                    <SchoolIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body2" noWrap>
                        Schools
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </>
          )}
        </>
      ) : (
        <List className={classes.menu}>
          <ListItem
            button
            component={Link}
            to="/"
            selected={location.pathname === '/'}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body2" noWrap>
                  Home
                </Typography>
              }
            />
          </ListItem>
        </List>
      )}
    </div>
  );

  return (
    <>
      <AuthenticatedTemplate>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            position="fixed"
            className={classes.appBar}
            elevation={0}
            color="transparent"
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <div className={classes.title}>
                <Hidden xsDown>
                  <Typography variant="h6">{pageTitle}</Typography>
                </Hidden>
                <Link to="/">
                  <img
                    src={BustleMark}
                    alt="Bustle logo"
                    className={`${classes.logo} ${classes.logoCenter}`}
                  />
                </Link>
              </div>
              <SupportMenu />
              {accounts && inProgress === 'none' && (
                <ProfileContent
                  instance={instance}
                  account={accounts[0]}
                />
              )}
            </Toolbar>
          </AppBar>
          <nav
            className={classes.drawer}
            aria-label="main menu items"
          >
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp>
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden xsDown>
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            {children}
          </main>
          {accounts &&
            inProgress === 'none' &&
            hasAdminRole(userQuery?.data?.role) &&
            userQuery?.data?.status !== 'PendingApproval' && (
              <InviteContact
                open={inviteContactOpen}
                setOpen={setInviteContactOpen}
                account={accounts[0]}
              />
            )}
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Landing />
      </UnauthenticatedTemplate>
    </>
  );
}

MainLayout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default MainLayout;

const useStyles = (props) =>
  makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: props.hideMenu ? 0 : theme.layout.drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.default,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${
          props.hideMenu ? 0 : theme.layout.drawerWidth
        }px)`,
        marginLeft: props.hideMenu ? 0 : theme.layout.drawerWidth,
      },
      '& .MuiToolbar-root': {
        paddingRight: 0,
      },
    },
    menuButton: {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: props.hideMenu ? 0 : theme.layout.drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(1, 0),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(0, 1, 1),
      },
    },
    logoCenter: {
      marginLeft: 'auto',
      marginRight: 'auto',
      transform: 'translateX(24px)',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    logo: {
      height: '36px',
      marginTop: theme.spacing(0.75),
      [theme.breakpoints.up('sm')]: {
        height: '40px',
        marginTop: theme.spacing(1.75),
        marginLeft: theme.spacing(1.75),
      },
    },
    title: {
      flex: 1,
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
      },
    },
    menu: {
      '& .MuiListItemIcon-root': {
        minWidth: '40px',
      },
      '& .MuiListItem-gutters': {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
      '& .Mui-selected': {
        backgroundColor: `rgba(${theme.palette.primary.mainRGB}, 0.2)`,
        color: theme.palette.primary.dark,

        '& .MuiListItemIcon-root': {
          color: theme.palette.primary.dark,
        },
      },
    },
    ctaItem: {
      marginLeft: -theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    ctaFab: {
      textTransform: 'none',
      fontSize: '0.9375rem',
      paddingRight: theme.spacing(3),
      marginBottom: theme.spacing(1),
      color: `rgba(255,255,255,1)`,
    },
    ctaIcon: {
      marginLeft: theme.spacing(-0.75),
      marginRight: theme.spacing(1.25),
      color: `rgba(255,255,255,0.7)`,
    },
  }));
