import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useMutation } from 'react-query';
import { useTokenQuery } from '../hooks/useTokenQuery';
import { useDispatch } from 'react-redux';
import { showInfoSnackbar } from '../redux/actions/uiActions';
import { useGetSchool } from '../hooks/contacts/useContactsQuery';

// TODO - update sendgrid email invite once backend work done
const validationSchema = yup.object({
  email: yup
    .string('Enter an email')
    .email('Enter a valid email')
    .required('Email is required'),
  name: yup.string('Enter a name').required('Name is required'),
});

const InviteContact = (props) => {
  const classes = useStyles();
  const { open, setOpen, account } = props;
  const tokenQuery = useTokenQuery();
  const dispatch = useDispatch();

  const school = useGetSchool();

  const { mutate: inviteContact } = useMutation(
    'contact_invite',
    (contact) => {
      let apiUrl = process.env.REACT_APP_API_URI.trim();

      return fetch(`${apiUrl}/contacts/invite`, {
        method: 'post',
        headers: new Headers({
          Authorization: 'Bearer ' + tokenQuery.data,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(contact),
      }).then((res) => {
        return res.json();
      });
    },
    {
      enabled: !!tokenQuery.data,
      onSuccess: () => {
        dispatch(showInfoSnackbar('Contact invited'));
      },
      onError: (err) => {
        dispatch(showInfoSnackbar('An errror occured'));
      },
    }
  );

  const handleClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      inviteContact({
        name: values.name,
        email: values.email,
        school: school.school,
        adminName: `${account.idTokenClaims.given_name} ${account.idTokenClaims.family_name}`,
      });
      handleClose();
    },
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="invite-contact-dialog-title"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle
        id="invite-contact-dialog-title"
        className={classes.title}
      >
        Invite contact
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent className={classes.content}>
          <TextField
            autoFocus
            margin="none"
            fullWidth
            id="name"
            name="name"
            label="Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <TextField
            margin="normal"
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={
              formik.touched.email && Boolean(formik.errors.email)
            }
            helperText={formik.touched.email && formik.errors.email}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={formik.isSubmitting}
            type="submit"
          >
            Invite
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default InviteContact;

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 0,
  },
  content: {
    '&.MuiDialogContent-root:first-child': {
      paddingTop: theme.spacing(1),
    },
  },
}));
