import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import useUpdateContact from '../../hooks/contacts/useUpdateContact';
import useUpdateContactGroup from '../../hooks/contactGroups/useUpdateContactGroup';
import { useContactGroupQuery } from '../../hooks/contactGroups/useContactGroupsQuery';
import { useDispatch } from 'react-redux';
import { showInfoSnackbar } from '../../redux/actions/uiActions';

const ContactApproval = (props) => {
  const { contact, school, buttonSize } = props;
  const [open, setOpen] = React.useState(false);
  const updateContact = useUpdateContact();
  const updateContactGroup = useUpdateContactGroup();
  const schoolContactGroup = useContactGroupQuery(school?.id).data;
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApproveContact = () => {
    const requestedArray = contact.requestedSchools
      .split(',')
      .filter((s) => s !== school?.id);

    let schoolContactsArray = schoolContactGroup.contacts;
    schoolContactsArray.push(contact);

    // Remove the approving school from the contact's requestedSchools property
    // Add the contact to the school contactGroup
    updateContact.mutate(
      {
        contact: {
          ...contact,
          status: 'Active',
          requestedSchools: requestedArray.length
            ? requestedArray.toString()
            : null,
        },
      },
      {
        onSuccess: () => {
          updateContactGroup.mutate(
            {
              contactGroup: {
                ...schoolContactGroup,
                contacts: schoolContactsArray,
              },
            },
            {
              onSuccess: () => {
                dispatch(showInfoSnackbar('Contact approved'));
                handleClose();
                // TODO - call api to send email to requesting contact
                // advising of their approval
              },
              onError: () => {
                dispatch(showInfoSnackbar('An error occurred'));
              },
            }
          );
        },
      }
    );
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size={buttonSize ? buttonSize : 'medium'}
        onClick={handleClickOpen}
      >
        Approve
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Approve this contact?'}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            By approving this contact you are confirming that they are
            known to the school and/or have been contacted and
            approved to work at your school
          </DialogContentText>
        </DialogContent>
        {updateContact.isLoading || updateContactGroup.isLoading ? (
          <Box
            height={64}
            display="flex"
            alignItems="center"
            justifyContent="center"
            pb={2}
          >
            <CircularProgress color="primary" size={24} />
          </Box>
        ) : (
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => handleApproveContact()}
              color="primary"
              autoFocus
            >
              Approve
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default ContactApproval;
