import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, Field, useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Typography from '@material-ui/core/Typography';
import { TextField, Select, RadioGroup } from 'formik-material-ui';
import { DatePicker, TimePicker } from 'formik-material-ui-pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '@material-ui/icons/HelpOutline';
import MomentUtils from '@date-io/moment';
import SuccessDialog from '../SuccessDialog';
import Loading from '../Loading';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import useCreateBooking from '../../hooks/bookings/useCreateBooking';
import { useContactGroupsQuery } from '../../hooks/contactGroups/useContactGroupsQuery';
import { useGetSchool } from '../../hooks/contacts/useContactsQuery';
import AddContacts from '../bookings/AddContacts';
import { showInfoSnackbar } from '../../redux/actions/uiActions';
import { useDispatch } from 'react-redux';
import { useBookingQuery } from '../../hooks/bookings/useBookingsQuery';
import { useParams } from 'react-router-dom';
import { getTimeCategory } from '../momentUtilities';

const CreateBookingForm = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [contactsValue, setContactsValue] = React.useState([]);
  const [bookingSuccess, setBookingSuccess] = React.useState(false);

  // If the booking is a copy, then get the booking details
  // How to only call this when the booking is a copy?
  const {
    data: booking,
    isLoading,
    isError,
    refetch,
  } = useBookingQuery(id, false);

  // Create a useEffect the runs on inital page load that calls booking refetch
  // This is added so we can get the booking details when the page is refreshed
  // but not when the page is refocused as the admin may have updated some
  // of the booking details. This works in conjunction with the useBookingQuery
  // hook that has a refetchOnWindowFocus (enabled) set to false
  // If will only call api is there is an id, indicating a copy
  React.useEffect(() => {
    if (id) {
      refetch();
    }
  }, [id, refetch]);

  // PingTime and PingDate
  const timeCategory = getTimeCategory(moment());

  const dispatch = useDispatch();

  const { mutate: createBooking } = useCreateBooking();
  const school = useGetSchool();

  const contactGroupsQuery = useContactGroupsQuery();
  const contactGroups = contactGroupsQuery?.data?.filter(
    (cg) => cg.school === school?.school && !cg.text.includes('all-')
  );

  const history = useHistory();

  const handleSuccessDialogClose = () => {
    setBookingSuccess(false);
    history.push('/');
  };

  React.useEffect(() => {
    if (booking?.contacts?.length > 0) {
      // Before setting the contacts value, we need to replace contactId with id
      // This is because the contacts array from the API is different from the contacts array
      // created by the AddContacts component - Will get Nimo to fix this
      booking.contacts.forEach((contact) => {
        contact.id = contact.contactId;
      });
      setContactsValue(booking.contacts);
    }
  }, [booking]);

  const leaveTypes = [
    { code: 'SL', description: 'Sick leave' },
    { code: 'LWP', description: 'Leave without pay' },
    { code: 'PL', description: 'Parental leave' },
    { code: 'CL', description: 'Carers leave' },
    { code: 'ID', description: 'Infectious diseases' },
    { code: 'PD', description: 'Professional development' },
    { code: 'LSL', description: 'Long service leave' },
  ];

  const StartDateField = (props) => {
    const {
      initialValues,
      setFieldValue,
      touched,
      values: { startDate, startTime },
    } = useFormikContext();

    React.useEffect(() => {
      if (!touched.startDate && id) {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to midnight for comparison

        const startDate = new Date(initialValues.startDate);
        startDate.setHours(0, 0, 0, 0);

        // Check if initialValues.startDate is before today
        if (startDate < today) {
          const hours = new Date().getHours();
          const minutes = new Date().getMinutes();
          const newDate = new Date().setHours(hours, minutes, 0, 0);
          // Set the field value to today
          setFieldValue('startDate', newDate);
        } else {
          // Use initialValues.startDate
          setFieldValue('startDate', initialValues.startDate);
        }
      }
      // Get the hours and minutes from startTime to update the startDate
      // This is what is used for the booking start date
      if (startTime) {
        const hours = new Date(startTime).getHours();
        const minutes = new Date(startTime).getMinutes();
        const newDate = new Date(startDate).setHours(
          hours,
          minutes,
          0,
          0
        );
        setFieldValue(props.name, newDate);
      }
    }, [
      touched.startTime,
      setFieldValue,
      props.name,
      touched.startDate,
      initialValues.startDate,
      startTime,
      startDate,
    ]);

    return (
      <>
        <Field
          component={DatePicker}
          label="Start Date"
          inputVariant="outlined"
          fullWidth
          disablePast
          {...props}
        />
      </>
    );
  };

  const EndDateField = (props) => {
    const {
      values: { startDate, endDate, endTime },
      touched,
      setFieldValue,
      initialValues,
    } = useFormikContext();

    React.useEffect(() => {
      // If the booking is a copy
      if (!touched.endDate && id) {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to midnight for comparison

        const endDate = new Date(initialValues.endDate);
        endDate.setHours(0, 0, 0, 0);

        // Check if initialValues.startDate is before today
        if (endDate < today) {
          const hours = new Date().getHours();
          const minutes = new Date().getMinutes();
          const newDate = new Date().setHours(hours, minutes, 0, 0);
          // Set the field value to today
          setFieldValue('endDate', newDate);
        } else {
          // Use initialValues.endDate
          setFieldValue('endDate', initialValues.endDate);
        }
      }

      // Get the hours and minutes from endTime to update the endDate
      // This is what is used for the booking end date
      if (endTime) {
        const hours = new Date(endTime).getHours();
        const minutes = new Date(endTime).getMinutes();
        const newDate = new Date(endDate).setHours(
          hours,
          minutes,
          0,
          0
        );
        setFieldValue(props.name, newDate);
      }

      // If startDate is touched then set the endDate to the same date
      // but with the hours and minutes from the endTime
      if (touched.startDate) {
        const hours = new Date(endTime).getHours();
        const minutes = new Date(endTime).getMinutes();
        const newDate = new Date(startDate).setHours(
          hours,
          minutes,
          0,
          0
        );
        setFieldValue(props.name, newDate);
      }

      // Allow the user to select the end date
      if (touched.endDate) {
        const hours = new Date(endTime).getHours();
        const minutes = new Date(endTime).getMinutes();
        const newDate = new Date(endDate).setHours(
          hours,
          minutes,
          0,
          0
        );
        setFieldValue(props.name, newDate);
      }
    }, [
      startDate,
      touched.startDate,
      touched.endDate,
      setFieldValue,
      props.name,
      touched.endTime,
      endTime,
      endDate,
      initialValues.endDate,
    ]);

    return (
      <>
        <Field
          component={DatePicker}
          label="End Date"
          inputVariant="outlined"
          fullWidth
          minDate={startDate}
          disablePast
          {...props}
        />
      </>
    );
  };

  const StartTimeField = (props) => {
    const {
      values: { startTime },
      touched,
      setFieldValue,
      initialValues,
    } = useFormikContext();

    React.useEffect(() => {
      // If the booking is new, then set the field value to the school start time
      if (
        !touched.startTime &&
        initialValues.startTime === undefined
      ) {
        const newDate = new Date().setHours(
          school?.startHours,
          school?.startMinutes,
          0,
          0
        );
        setFieldValue(props.name, newDate);
      }

      // If the booking is a copy, then just check if there is an id
      // and set the field value to the initial value
      if (!touched.startTime && id) {
        const hours = new Date(initialValues.startDate).getHours();
        const minutes = new Date(
          initialValues.startDate
        ).getMinutes();
        const newDate = new Date(initialValues.startDate).setHours(
          hours,
          minutes,
          0,
          0
        );
        setFieldValue(props.name, newDate);
      }
    }, [
      touched.startTime,
      setFieldValue,
      props.name,
      startTime,
      initialValues.startTime,
      initialValues.startDate,
    ]);

    return (
      <>
        <Field
          component={TimePicker}
          name="startTime"
          label="Start Time"
          inputVariant="outlined"
          fullWidth
        />
      </>
    );
  };

  const EndTimeField = (props) => {
    const {
      values: { endTime },
      touched,
      setFieldValue,
      initialValues,
    } = useFormikContext();

    React.useEffect(() => {
      // If the booking is new, then set the field value to the school end time
      if (!touched.endTime && initialValues.endTime === undefined) {
        const newDate = new Date().setHours(
          school?.endHours,
          school?.endMinutes,
          0,
          0
        );
        setFieldValue(props.name, newDate);
      }

      // If the booking is a copy, then just check if there is an id
      // and set the field value to the initial value
      if (!touched.endTime && id) {
        const hours = new Date(initialValues.endDate).getHours();
        const minutes = new Date(initialValues.endDate).getMinutes();
        const newDate = new Date(initialValues.endDate).setHours(
          hours,
          minutes,
          0,
          0
        );
        setFieldValue(props.name, newDate);
      }
    }, [
      setFieldValue,
      props.name,
      touched.endTime,
      endTime,
      initialValues.endTime,
      initialValues.endDate,
    ]);

    return (
      <>
        <Field
          component={TimePicker}
          name="endTime"
          label="End Time"
          inputVariant="outlined"
          fullWidth
        />
      </>
    );
  };

  const PingStartDateField = (props) => {
    const {
      values: { pingStartDate, pingStartTime, startDate },
      touched,
      setFieldValue,
    } = useFormikContext();

    React.useEffect(() => {
      if (pingStartTime !== '') {
        const hours = new Date(pingStartTime).getHours();
        const minutes = new Date(pingStartTime).getMinutes();
        const newDate = new Date(pingStartDate).setHours(
          hours,
          minutes,
          0,
          0
        );
        setFieldValue(props.name, newDate);
      }
    }, [
      pingStartTime,
      touched.pingStartTime,
      setFieldValue,
      props.name,
      pingStartDate,
    ]);

    return (
      <>
        <Field
          component={DatePicker}
          label="Notify Start Date"
          inputVariant="outlined"
          fullWidth
          disablePast
          maxDate={startDate}
          {...props}
        />
      </>
    );
  };

  const PingTimeReset = () => {
    const { setFieldValue } = useFormikContext();

    const handlePingTimeReset = () => {
      // use context like as fields above
      setFieldValue('pingStartDate', new Date());
      setFieldValue('pingStartTime', new Date());
    };

    return (
      <Button color="primary" onClick={handlePingTimeReset}>
        Reset to now
      </Button>
    );
  };

  const FindATeacherField = ({ isSubmitting }) => {
    const { initialValues, setFieldValue } = useFormikContext();

    React.useEffect(() => {
      // initial value is undefined
      if (!initialValues.sendSms) {
        // Set the default value for the field
        setFieldValue('sendSms', 'send');
      }
    }, [initialValues.sendSms, setFieldValue]);

    return (
      <Field
        component={RadioGroup}
        name="sendSms"
        style={{ marginLeft: '8px' }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={5} sm={4}>
            <FormControlLabel
              value="send"
              control={<Radio disabled={isSubmitting} />}
              label="Find a teacher"
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={5} sm={6}>
            <FormControlLabel
              value="no send"
              control={<Radio disabled={isSubmitting} />}
              label="Pre-confirmed"
              disabled={isSubmitting}
            />
          </Grid>
          <Grid item xs={2}>
            <Box display="flex" flexDirection="row-reverse">
              <IconButton
                aria-label="booking type help"
                style={{ marginRight: '-8px' }}
              >
                <HelpIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Field>
    );
  };

  const ResponseTimeField = () => {
    const { setFieldValue, initialValues, touched } =
      useFormikContext();

    React.useEffect(() => {
      // Check if the field is untouched and the initial value is undefined
      if (
        !touched.responseTimeMinutes &&
        initialValues.responseTimeMinutes !== undefined
      ) {
        // Update the field value with the initial response time
        setFieldValue(
          'responseTimeMinutes',
          initialValues.responseTimeMinutes
        );
      }
      // If the field is untouched and the initial value is undefined
      // set the field value to the default response time of 3 minutes
      if (
        !touched.responseTimeMinutes &&
        initialValues.responseTimeMinutes === undefined
      )
        setFieldValue('responseTimeMinutes', 3);
    }, [
      initialValues.responseTimeMinutes,
      setFieldValue,
      touched.responseTimeMinutes,
    ]);

    return (
      <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor="responseTimeMinutes">
          Response time
        </InputLabel>
        <Field
          component={Select}
          name="responseTimeMinutes"
          label="Response time"
          inputProps={{
            id: 'responseTimeMinutes',
          }}
        >
          <MenuItem value={1}>1 Minute</MenuItem>
          <MenuItem value={3}>3 Minutes</MenuItem>
          <MenuItem value={5}>5 Minutes</MenuItem>
          <MenuItem value={10}>10 Minutes</MenuItem>
          <MenuItem value={60}>1 Hour</MenuItem>
          <MenuItem value={1440}>24 Hours</MenuItem>
        </Field>
      </FormControl>
    );
  };

  const TeacherCoveredField = () => {
    const { setFieldValue, initialValues, touched } =
      useFormikContext();

    React.useEffect(() => {
      // If initial value is not undefined, set the field value to the initial value
      if (!touched.teacher && initialValues.teacher !== undefined) {
        setFieldValue('teacher', initialValues.teacher);
      }
      // If the field is untouched and the initial value is undefined
      // set the field value to the first teacher or 'None'
      if (
        school.teachers !== undefined &&
        !touched.teacher &&
        initialValues.teacher === undefined
      ) {
        // Update the field value with the default teacher or 'None'
        const defaultTeacher = 'None';
        setFieldValue('teacher', defaultTeacher);
      }
    }, [initialValues.teacher, setFieldValue, touched.teacher]);

    // Parse the teachers string into an array and remove the quotes
    const teachersArray = school.teachers
      ? school.teachers
          .replace(/"/g, '')
          .split(',')
          .map((teacher) => teacher.trim())
      : [];

    return (
      <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor="teacher">Teacher covered</InputLabel>
        <Field
          component={Select}
          name="teacher"
          label="Teacher covered"
          inputProps={{
            id: 'teacher',
          }}
        >
          <MenuItem value={'None'}>None</MenuItem>
          {teachersArray.map((teacher, index) => (
            <MenuItem key={index} value={teacher}>
              {teacher}
            </MenuItem>
          ))}
        </Field>
      </FormControl>
    );
  };

  const LeaveTypeField = () => {
    const { initialValues, setFieldValue, touched } =
      useFormikContext();

    React.useEffect(() => {
      // Check if the field is untouched and the initial value is undefined
      if (
        !touched.leaveType &&
        initialValues.leaveType !== undefined
      ) {
        // Update the field value with the default leave type or 'None'
        setFieldValue('leaveType', initialValues.leaveType);
      }
      // If the field is untouched and the initial value is undefined
      // set the field value to the first leave type or 'None'
      if (
        !touched.leaveType &&
        initialValues.leaveType === undefined
      ) {
        // Update the field value with the default leave type of SL
        const defaultLeaveType = 'SL';
        setFieldValue('leaveType', defaultLeaveType);
      }
    }, [
      initialValues.leaveType,
      initialValues.leaveTypes,
      setFieldValue,
      touched.leaveType,
    ]);

    return (
      <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor="leaveType">Leave type</InputLabel>
        <Field
          component={Select}
          name="leaveType"
          label="Leave type"
          inputProps={{
            id: 'leaveType',
          }}
        >
          {leaveTypes.map((leave) => (
            <MenuItem key={leave.description} value={leave.code}>
              {leave.description}
            </MenuItem>
          ))}
        </Field>
      </FormControl>
    );
  };

  // If the booking is a copy, then show the loading component
  if (id && isLoading) {
    return <Loading />;
  }
  // If the booking is a copy and there is an error some error component
  if (id && isError) {
    return <div>There was error copying this booking</div>;
  }

  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Formik
          initialValues={{
            startDate: booking?.startDate || new Date(),
            endDate: booking?.endDate || new Date(),
            pingStartDate:
              timeCategory === 'NIGHT'
                ? new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
                : new Date(),
            pingStartTime:
              timeCategory === 'NOW'
                ? new Date()
                : timeCategory === 'AFTERNOON'
                ? new Date().setHours(16, 0, 0, 0)
                : timeCategory === 'NIGHT'
                ? new Date(
                    new Date().getTime() + 24 * 60 * 60 * 1000
                  ).setHours(6, 0, 0, 0)
                : new Date().setHours(6, 0, 0, 0),
            notes: booking?.notes || '',
            teacher: booking?.teacher || 'None',
            leaveType: booking?.leaveType || 'SL',
            // sendSms: booking?.sendSms ? 'send' : 'no send',
            sendSms: booking?.sendSms || 'send', // get this added to the booking response then remove this line
            responseTimeMinutes: booking?.responseTimeMinutes || 3,
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            // Create contacts array for the API
            // If the booking is copied, then the contacts array will be the same
            // without the rejected contacts
            const contactsArray = contactsValue
              .filter((c) => c.status !== 'rejected')
              .map((contact, index) => {
                return {
                  contactId: contact.id,
                  status:
                    values.sendSms === 'send' ? 'idle' : 'accepted',
                  index: index + 1,
                };
              });

            setTimeout(() => {
              setSubmitting(false);

              const data = {
                startDate: `${moment
                  .utc(values.startDate)
                  .format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`,
                endDate: `${moment
                  .utc(values.endDate)
                  .format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`,
                pingStartDate:
                  values.sendSms === 'send'
                    ? `${moment
                        .utc(values.pingStartDate)
                        .format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`
                    : `${moment
                        .utc()
                        .format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`,
                notes: values.notes,
                contacts: contactsArray,
                responseTimeMinutes:
                  values.sendSms === 'send'
                    ? values.responseTimeMinutes
                    : 1,
                leaveType: values.leaveType,
                sendSms: values.sendSms === 'send' ? true : false,
                teacher: values.teacher,
                contactGroupId: school?.id,
              };
              resetForm();
              console.log(data);

              // Call API
              createBooking(
                {
                  booking: {
                    ...data,
                  },
                },
                {
                  onSuccess: () => {
                    setContactsValue([]);
                    values.sendSms === 'send'
                      ? setBookingSuccess(true)
                      : dispatch(showInfoSnackbar('Booking created'));
                  },
                  onError: (error) => {
                    console.log(error);
                  },
                }
              );
            }, 1000);
          }}
        >
          {({ submitForm, isSubmitting, values }) =>
            isSubmitting ? (
              <Loading />
            ) : (
              <Form>
                <AppBar
                  position="static"
                  color="transparent"
                  elevation={0}
                >
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="back"
                      component={Link}
                      to="/"
                    >
                      <CloseIcon />
                    </IconButton>
                    <Typography
                      variant="h6"
                      className={classes.title}
                    >
                      Create Booking
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        contactsValue.filter(
                          (c) => c.status !== 'rejected'
                        ).length === 0
                      }
                      onClick={submitForm}
                      className={classes.actionButton}
                      size="large"
                    >
                      Create
                    </Button>
                  </Toolbar>
                </AppBar>
                <section className={classes.root}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <StartDateField name="startDate" />
                    </Grid>
                    <Grid item xs={6}>
                      <StartTimeField name="startTime" />
                    </Grid>
                    <Grid item xs={6}>
                      <EndDateField name="endDate" />
                    </Grid>
                    <Grid item xs={6}>
                      <EndTimeField name="endTime" />
                    </Grid>
                    <Grid item xs={12}>
                      <FindATeacherField
                        isSubmitting={isSubmitting}
                      />
                    </Grid>
                    <AddContacts
                      contactsValue={contactsValue}
                      setContactsValue={setContactsValue}
                      contacts={school?.contacts}
                      contactGroups={contactGroups}
                    />
                    {values.sendSms === 'send' && (
                      <>
                        <Grid item xs={12}>
                          <ResponseTimeField />
                        </Grid>
                        <Grid item xs={6}>
                          <PingStartDateField name="pingStartDate" />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            component={TimePicker}
                            label="Notify Start Time"
                            inputVariant="outlined"
                            fullWidth
                            name="pingStartTime"
                          />
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: 0 }}>
                          <PingTimeReset />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        multiline
                        rows={4}
                        name="notes"
                        type="text"
                        label="Notes"
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TeacherCoveredField />
                    </Grid>
                    <Grid item xs={12}>
                      <LeaveTypeField />
                    </Grid>
                  </Grid>
                </section>
              </Form>
            )
          }
        </Formik>
      </MuiPickersUtilsProvider>
      <SuccessDialog
        open={bookingSuccess}
        handleClose={handleSuccessDialogClose}
      />
    </>
  );
};

export default CreateBookingForm;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: '34em',
  },
  title: {
    fontSize: theme.typography.pxToRem(17),
  },
  actionButton: {
    marginLeft: 'auto',
  },
}));
