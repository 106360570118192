import { useMutation, useQueryClient } from 'react-query';
import { useTokenQuery } from '../useTokenQuery';

export default function useUpdateContactGroup() {
  const tokenQuery = useTokenQuery();
  const queryClient = useQueryClient();

  return useMutation(
    'contactGroup_update',
    (data) => {
      let apiUrl = process.env.REACT_APP_API_URI.trim();

      return fetch(
        `${apiUrl}/contacts/groups/${data.contactGroup.id}`,
        {
          method: 'put',
          headers: new Headers({
            Authorization: 'Bearer ' + tokenQuery.data,
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify(data),
        }
      ).then((res) => {
        return res.json();
      });
    },
    {
      enabled: !!tokenQuery.data,
      onSuccess: (_, data) => {
        queryClient.invalidateQueries([
          'contactGroup',
          data.contactGroup.id,
        ]);
        queryClient.invalidateQueries('contactGroups');
        queryClient.invalidateQueries([
          'pending_contacts',
          data.contactGroup.schoolId,
        ]);
      },
    }
  );
}
