import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { useAccount, useMsal } from '@azure/msal-react';
import { useContactQuery } from '../hooks/contacts/useContactsQuery';
import CoffeeMachine from '../assets/images/coffee_machine.svg';

const WaitingForApproval = () => {
  const classes = useStyles();
  const { accounts } = useMsal();
  const account = useAccount(accounts[0]) || {};

  const contactQuery = useContactQuery(account.username);

  // Add a redirect back to home in case the user is still sitting
  // on this page and is then approved
  if (
    contactQuery.isSuccess &&
    contactQuery.data?.status !== 'PendingApproval'
  ) {
    return (
      <Redirect
        to={{
          pathname: `/`,
        }}
      />
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h6" gutterBottom>
          Great work!
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          gutterBottom
        >
          Now sit back and relax whilst we check that info...it
          shouldn't take us long and we'll send you an email when
          we're done.
        </Typography>
      </div>
      <img
        src={CoffeeMachine}
        alt="Coffee maching"
        className={classes.img}
      />
    </div>
  );
};

export default WaitingForApproval;

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '34em',
  },
  content: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(8),
  },
  img: {
    maxWidth: '100%',
  },
}));
