import { useMutation, useQueryClient } from 'react-query';
import { useTokenQuery } from '../useTokenQuery';

export default function useCreateSchool() {
  const tokenQuery = useTokenQuery();
  const queryClient = useQueryClient();

  return useMutation(
    'school_create',
    (school) => {
      let apiUrl = process.env.REACT_APP_API_URI.trim();

      return fetch(`${apiUrl}/schools`, {
        method: 'post',
        headers: new Headers({
          Authorization: 'Bearer ' + tokenQuery.data,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(school),
      }).then((res) => {
        queryClient.invalidateQueries(['school', school.id]);
        queryClient.invalidateQueries('schools');
        return res.json();
      });
    },
    {
      enabled: !!tokenQuery.data,
    }
  );
}
