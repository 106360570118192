import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import PhoneIcon from '@material-ui/icons/PhoneIphone';
import ScheduleIcon from '@material-ui/icons/ScheduleOutlined';
import PlaceIcon from '@material-ui/icons/PlaceOutlined';
import { green } from '@material-ui/core/colors';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import UserAvatar from '../components/UserAvatar';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useContactGroupsQuery } from '../hooks/contactGroups/useContactGroupsQuery';
import Loading from '../components/Loading';
import NotFound from './404';
import moment from 'moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Formik, Form, Field, useFormikContext } from 'formik';
import { TextField, RadioGroup } from 'formik-material-ui';
import { TimePicker } from 'formik-material-ui-pickers';
import useUpdateContactGroup from '../hooks/contactGroups/useUpdateContactGroup';
import { useDispatch } from 'react-redux';
import { showInfoSnackbar } from '../redux/actions/uiActions';
import { hasAdminRole } from '../components/utilities';
import { useSchoolQuery } from '../hooks/schools/useSchoolsQuery';
import useUpdateSchool from '../hooks/schools/useUpdateSchool';
import { usePendingContactsForSchool } from '../hooks/contacts/usePendingContactsForSchool';
import ContactListItem from '../components/contact/ContactListItem';

// TODO - test the superAdmin is added to new school after creation and can see their bookings

const getHourAndMinute = (date) => {
  const hour = moment(date).hour();
  const minute = moment(date).minute();
  return { hour, minute };
};

const School = () => {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const [editState, setEditState] = React.useState(false);
  const dispatch = useDispatch();

  const contactGroupsQuery = useContactGroupsQuery();
  const contactGroup = contactGroupsQuery?.data?.find(
    (cg) => cg.schoolId === id && cg.text.includes('all-')
  );

  // Get the school details
  const { data: schoolDetails } = useSchoolQuery(id);

  // Are there contacts waiting to be approved?
  // These contacts should have 'requestedSchools' property
  const { data: pendingContactsForSchool } =
    usePendingContactsForSchool(id);

  const handleBack = () =>
    editState ? setEditState(false) : history.goBack();

  const { mutate: editSchool } = useUpdateSchool(id);
  const updateContactGroup = useUpdateContactGroup();

  const updateSchoolDetails = (updatedData) => {
    // Here we need to add superAdmins to the school contact group

    updateContactGroup.mutate(
      {
        contactGroup: {
          ...updatedData,
        },
      },
      {
        onSuccess: () => {
          // Invalidate and refetch the query after a successful update
          // queryClient.invalidateQueries(['schools', id]);
          setEditState(false);
          dispatch(showInfoSnackbar('Saved'));
        },
      }
    );
  };

  const AddressField = (props) => {
    const {
      values: { address },
      touched,
      setFieldValue,
    } = useFormikContext();

    React.useEffect(() => {
      if (!touched.address) {
        setFieldValue(props.name, schoolDetails?.address);
      }
    }, [touched.address, setFieldValue, props.name, address]);

    return (
      <>
        <Field
          component={TextField}
          multiline
          name="address"
          type="text"
          label="Address"
          fullWidth
          variant="outlined"
        />
      </>
    );
  };

  const PhoneField = (props) => {
    const {
      values: { phone },
      touched,
      setFieldValue,
    } = useFormikContext();

    React.useEffect(() => {
      if (!touched.phone) {
        setFieldValue(props.name, schoolDetails?.phone);
      }
    }, [touched.phone, setFieldValue, props.name, phone]);

    return (
      <>
        <Field
          component={TextField}
          multiline
          name="phone"
          type="number"
          label="Phone"
          fullWidth
          variant="outlined"
        />
      </>
    );
  };

  const StartTimeField = (props) => {
    const { touched, setFieldValue, initialValues } =
      useFormikContext();

    React.useEffect(() => {
      if (!touched.startTime && initialValues.startTime) {
        // If the field has not been touched and initialValues has startTime
        setFieldValue(props.name, initialValues.startTime);
      } else if (!touched.startTime && contactGroup) {
        // If the field has not been touched and contactGroup is defined
        const newDate = new Date().setHours(
          contactGroup.startHours,
          contactGroup.startMinutes,
          0,
          0
        );
        setFieldValue(props.name, newDate);
      }
    }, [
      setFieldValue,
      props.name,
      touched.startTime,
      initialValues.startTime,
    ]);

    return (
      <>
        <Field
          component={TimePicker}
          name="startTime"
          label="Start Time"
          inputVariant="outlined"
          fullWidth
        />
      </>
    );
  };

  const EndTimeField = (props) => {
    const { touched, setFieldValue, initialValues } =
      useFormikContext();

    React.useEffect(() => {
      if (!touched.endTime && initialValues.endTime) {
        // If the field has not been touched and initialValues has endTime
        setFieldValue(props.name, initialValues.endTime);
      } else if (!touched.endTime && contactGroup) {
        // If the field has not been touched and contactGroup is defined
        const newDate = new Date().setHours(
          contactGroup.endHours,
          contactGroup.endMinutes,
          0,
          0
        );
        setFieldValue(props.name, newDate);
      }
    }, [
      setFieldValue,
      props.name,
      touched.endTime,
      initialValues.endTime,
    ]);

    return (
      <>
        <Field
          component={TimePicker}
          name="endTime"
          label="End Time"
          inputVariant="outlined"
          fullWidth
        />
      </>
    );
  };

  const StatusField = (props) => {
    const { setFieldValue, initialValues } = useFormikContext();

    React.useEffect(() => {
      // Set the initial value
      if (!initialValues.status) {
        setFieldValue(props.name, schoolDetails?.status || '');
      }
    }, [initialValues.status, setFieldValue, props.name]);

    return (
      <>
        <FormLabel component="legend">Status</FormLabel>
        <Field
          component={RadioGroup}
          name="status"
          style={{ marginLeft: '8px' }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={5} sm={4}>
              <FormControlLabel
                value="active"
                control={<Radio disabled={props.isSubmitting} />}
                label="Active"
                disabled={props.isSubmitting}
              />
            </Grid>
            <Grid item xs={5} sm={6}>
              <FormControlLabel
                value="inactive"
                control={<Radio disabled={props.isSubmitting} />}
                label="Inactive"
                disabled={props.isSubmitting}
              />
            </Grid>
          </Grid>
        </Field>
      </>
    );
  };

  return contactGroupsQuery.isLoading ? (
    <Loading />
  ) : contactGroupsQuery?.data?.statusCode === 500 ? (
    <NotFound />
  ) : (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Formik
          initialValues={{
            address: schoolDetails?.address,
            phone: schoolDetails?.phone,
            startTime: new Date().setHours(
              contactGroup?.startHours,
              contactGroup?.startMinutes,
              0,
              0
            ),
            endTime: new Date().setHours(
              contactGroup?.endHours,
              contactGroup?.endMinutes,
              0,
              0
            ),
            status: schoolDetails?.status,
          }}
          enableReinitialize
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setTimeout(() => {
              setSubmitting(false);

              const start = getHourAndMinute(values.startTime);
              const end = getHourAndMinute(values.endTime);

              // TODO - update the school and update the contactGroup

              const updatedSchool = {
                name: schoolDetails.name,
                address: values.address,
                phone: values.phone,
                status: values.status,
              };

              console.log(updatedSchool);

              // Update the data
              const updatedData = {
                ...contactGroup,
                startHours: start.hour,
                startMinutes: start.minute,
                endHours: end.hour,
                endMinutes: end.minute,
                address: values.address,
                phone: values.phone,
              };

              console.log(updatedData);

              editSchool(updatedSchool, {
                onSuccess: () => {
                  // Call mutation to update the data

                  // Update the data
                  const updatedData = {
                    ...contactGroup,
                    startHours: start.hour,
                    startMinutes: start.minute,
                    endHours: end.hour,
                    endMinutes: end.minute,
                    address: values.address,
                    phone: values.phone,
                  };

                  updateSchoolDetails(updatedData);
                },
              });

              resetForm();
            }, 1000);
          }}
        >
          {({ submitForm, isSubmitting, values, resetForm }) =>
            isSubmitting ? (
              <Loading />
            ) : (
              <Form>
                <AppBar
                  position="static"
                  color="transparent"
                  elevation={0}
                >
                  <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="back"
                      onClick={handleBack}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                    {editState ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          submitForm();
                          setEditState(false);
                        }}
                        className={classes.actionButton}
                        size="large"
                      >
                        Save
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setEditState(!editState);
                        }}
                        className={classes.actionButton}
                        size="large"
                      >
                        Edit
                      </Button>
                    )}
                  </Toolbar>
                </AppBar>
                <section className={classes.root}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    mb={2}
                  >
                    <div className={classes.avatar}>
                      <UserAvatar
                        size="large"
                        label={
                          schoolDetails
                            ? `${schoolDetails?.name[0]}`
                            : '?'
                        }
                      />
                    </div>
                    {contactGroup && (
                      <>
                        <Typography
                          variant="h5"
                          className={classes.title}
                        >
                          {schoolDetails?.name}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          className={
                            schoolDetails?.status === 'active'
                              ? classes.active
                              : classes.inactive
                          }
                        >
                          {schoolDetails?.status}
                        </Typography>
                      </>
                    )}
                  </Box>

                  {editState ? (
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <AddressField name="address" />
                      </Grid>
                      <Grid item xs={12}>
                        <PhoneField name="phone" />
                      </Grid>
                      <Grid item xs={12}>
                        <StartTimeField name="startTime" />
                      </Grid>
                      <Grid item xs={12}>
                        <EndTimeField name="endTime" />
                      </Grid>
                      <Grid item xs={12}>
                        <StatusField
                          name="status"
                          isSubmitting={isSubmitting}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Card variant="outlined" className={classes.card}>
                      <CardContent>
                        <Typography variant="subtitle1" gutterBottom>
                          School details
                        </Typography>
                        <List dense className={classes.list}>
                          <ListItem>
                            <ListItemIcon>
                              <PlaceIcon />
                            </ListItemIcon>
                            <ListItemText>
                              <Typography>
                                {schoolDetails?.address}
                              </Typography>
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <PhoneIcon />
                            </ListItemIcon>
                            <ListItemText>
                              <Typography>
                                <a
                                  href={`tel:${schoolDetails?.phone}`}
                                >
                                  {schoolDetails?.phone}
                                </a>
                              </Typography>
                            </ListItemText>
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <ScheduleIcon />
                            </ListItemIcon>
                            <ListItemText>
                              <Typography>
                                {contactGroup?.startHours}:{}
                                {contactGroup?.startMinutes}am -{' '}
                                {contactGroup?.endHours}:{}
                                {contactGroup?.endMinutes}pm
                              </Typography>
                            </ListItemText>
                          </ListItem>
                        </List>
                      </CardContent>
                    </Card>
                  )}

                  <>
                    {pendingContactsForSchool && (
                      <List
                        component="nav"
                        aria-label="contact list"
                        subheader={
                          pendingContactsForSchool?.length ? (
                            <ListSubheader
                              component="div"
                              id="list-subheader"
                            >
                              Waiting for approval (
                              {pendingContactsForSchool?.length})
                            </ListSubheader>
                          ) : (
                            false
                          )
                        }
                      >
                        {pendingContactsForSchool
                          ?.filter((c) => c.firstName)
                          .sort((a, b) => {
                            let nameA = a.firstName.toLowerCase();
                            let nameB = b.firstName.toLowerCase();
                            return nameA !== nameB
                              ? nameA < nameB
                                ? -1
                                : 1
                              : 0;
                          })
                          .map((contact) => {
                            return (
                              <ContactListItem
                                contact={contact}
                                key={contact.id}
                                contactListCount={0}
                                school={contactGroup}
                                waitingForApproval={true}
                              />
                            );
                          })}
                      </List>
                    )}
                    <List
                      component="nav"
                      aria-label="contact list"
                      subheader={
                        <ListSubheader
                          component="div"
                          id="list-subheader"
                        >
                          Contacts ({contactGroup?.contacts.length})
                        </ListSubheader>
                      }
                    >
                      {contactGroup?.contacts.length === 0 ? (
                        <ListItem>
                          <ListItemText>
                            <Typography>No contacts found</Typography>
                          </ListItemText>
                        </ListItem>
                      ) : (
                        contactGroup?.contacts
                          ?.filter((c) => c.firstName)
                          .sort((a, b) => {
                            let nameA = a.firstName.toLowerCase();
                            let nameB = b.firstName.toLowerCase();
                            return nameA !== nameB
                              ? nameA < nameB
                                ? -1
                                : 1
                              : 0;
                          })
                          .map((contact) => {
                            return (
                              <ListItem
                                key={contact.id}
                                button
                                component={Link}
                                to={`../contact/${contact.id}`}
                                className={classes.listItem}
                              >
                                <ListItemAvatar>
                                  <UserAvatar
                                    label={`${contact.firstName[0]}${contact.surname[0]}`}
                                  />
                                </ListItemAvatar>
                                <ListItemText
                                  id={contact.id}
                                  primary={
                                    <div>
                                      {`${contact.firstName} ${contact.surname}`}{' '}
                                      {hasAdminRole(contact.role) && (
                                        <Chip
                                          size="small"
                                          label={contact.role}
                                          className={classes.role}
                                        />
                                      )}
                                    </div>
                                  }
                                  secondary={
                                    <Typography
                                      variant="caption"
                                      className={
                                        contactGroup.status ===
                                        'active'
                                          ? classes.active
                                          : classes.inactive
                                      }
                                    >
                                      {contact.status}
                                    </Typography>
                                  }
                                  className={
                                    contact.status === 'inactive'
                                      ? classes.inactive
                                      : null
                                  }
                                />
                              </ListItem>
                            );
                          })
                      )}
                    </List>
                  </>
                </section>
              </Form>
            )
          }
        </Formik>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default School;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2, 2),
    maxWidth: '34em',
  },
  actionButton: {
    marginLeft: 'auto',
  },
  active: {
    color: green[700],
    textTransform: 'capitalize',
  },
  inactive: {
    color: theme.palette.text.secondary,
    textTransform: 'capitalize',
  },
  card: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius * 2,
    marginBottom: theme.spacing(2),
    '& .MuiCardContent-root': {
      paddingBottom: theme.spacing(2),
    },
    '& .MuiListItemIcon-root': {
      minWidth: theme.spacing(5),
    },
  },
  list: {
    '& .MuiListItem-gutters': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '& a': {
      textDecoration: 'none',
      color: theme.palette.primary.dark,
    },
  },
}));
