import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Loading from '../components/Loading';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Link, Redirect } from 'react-router-dom';
import CalendarView from '../components/bookings/CalendarView';
import EmptyState from '../components/EmptyState';
import { useContactQuery } from '../hooks/contacts/useContactsQuery';
import { useAccount, useMsal } from '@azure/msal-react';
import { hasAdminRole } from '../components/utilities';

const Home = (props) => {
  const classes = useStyles();
  const { accounts } = useMsal();
  const account = useAccount(accounts[0]) || {};

  const contactQuery = useContactQuery(account.username);

  // If the its a new user send them to get started
  if (
    contactQuery.isSuccess &&
    contactQuery.data.statusCode === 404
  ) {
    return (
      <Redirect
        to={{
          pathname: `/get-started`,
        }}
      />
    );
  }

  // If new redirect to getting started process
  if (contactQuery.isSuccess && contactQuery.data?.status === 'New') {
    return (
      <Redirect
        to={{
          pathname: '/get-started',
        }}
      />
    );
  }

  // If pending approval redirect to waiting for approval screen
  if (
    contactQuery.isSuccess &&
    contactQuery.data?.status === 'PendingApproval'
  ) {
    return (
      <Redirect
        to={{
          pathname: '/waiting-for-approval',
        }}
      />
    );
  }

  // Hide the calendar view component behind the
  // contactQuery so New signups can't see the
  // bookings network response
  return (
    <>
      {contactQuery.isLoading ? (
        <Loading />
      ) : contactQuery.isError ? (
        <EmptyState text="An error occurred" icon="not-found" />
      ) : (
        <section className={classes.root}>
          {contactQuery.data?.status !== 'New' && (
            <CalendarView user={props.user} />
          )}
        </section>
      )}
      {hasAdminRole(props.user?.role) && (
        <Fab
          color="primary"
          aria-label="add booking"
          className={classes.fab}
          component={Link}
          to="/booking/new"
        >
          <AddIcon fontSize="large" />
        </Fab>
      )}
    </>
  );
};

export default Home;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2, 1),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(3),
    },
  },
  tabs: {
    '& .MuiTab-root': {
      textTransform: 'none',
      minWidth: 96,
      fontSize: '15px',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& .Mui-selected': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    color: `rgba(255,255,255,0.7)`,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));
