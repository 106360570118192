import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const UserAvatar = (props) => {
  const classes = useStyles(props)();
  return <Avatar className={classes.root}>{props.label}</Avatar>;
};

export default UserAvatar;

const useStyles = (props) =>
  makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.dark,
      fontWeight: theme.typography.fontWeightBold,
      fontSize:
        props.size === 'large'
          ? '3rem'
          : props.size === 'small'
          ? '0.75rem'
          : '1rem',
      width:
        props.size === 'large'
          ? theme.spacing(15)
          : props.size === 'small'
          ? theme.spacing(3.5)
          : theme.spacing(5),
      height:
        props.size === 'large'
          ? theme.spacing(15)
          : props.size === 'small'
          ? theme.spacing(3.5)
          : theme.spacing(5),
    },
  }));
