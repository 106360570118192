import { useMutation, useQueryClient } from 'react-query';
import { useTokenQuery } from '../useTokenQuery';

export default function useUpdateContact() {
  const tokenQuery = useTokenQuery();
  const queryClient = useQueryClient();

  return useMutation(
    'contact_update',
    (contact) => {
      let apiUrl = process.env.REACT_APP_API_URI.trim();

      return fetch(`${apiUrl}/contacts`, {
        method: 'put',
        headers: new Headers({
          Authorization: 'Bearer ' + tokenQuery.data,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(contact),
      }).then((res) => res.json());
    },
    {
      enabled: !!tokenQuery.data,
      onSuccess: (_, data) => {
        queryClient.invalidateQueries(['contacts', data.contact.id]);
        queryClient.invalidateQueries('contacts');
        queryClient.invalidateQueries('contactGroups');
      },
    }
  );
}
