import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AddIcon from '@material-ui/icons/Add';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import AddList from './AddList';
import ListsMenuItem from './ListsMenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useContactGroupsQuery } from '../hooks/contactGroups/useContactGroupsQuery';
import { useGetSchool } from '../hooks/contacts/useContactsQuery';
import { useSchoolQuery } from '../hooks/schools/useSchoolsQuery';

const ListsMenu = (props) => {
  const classes = useStyles();
  const [listsOpen, setListOpen] = useState(true);
  const [addListOpen, setAddListOpen] = useState(false);
  const contactGroupsQuery = useContactGroupsQuery();

  const handleAddListOpen = () => {
    setAddListOpen(true);
  };

  const handleListToggle = () => {
    setListOpen(!listsOpen);
  };
  const school = useGetSchool();

  // Get the school details
  const { data: schoolDetails } = useSchoolQuery(school?.schoolId);

  return (
    <>
      {contactGroupsQuery.isLoading ? (
        <ListItem className={classes.loader}>
          <CircularProgress color="primary" size={24} />
        </ListItem>
      ) : (
        <List className={classes.menu}>
          <ListItem button onClick={handleListToggle}>
            <ListItemIcon>
              {listsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography variant="body2" noWrap>
                  Lists{' '}
                  <Typography variant="caption">
                    ({schoolDetails?.name})
                  </Typography>
                </Typography>
              }
            />
          </ListItem>

          {contactGroupsQuery.isLoading ? (
            <ListItem className={classes.loader}>
              <CircularProgress color="primary" size={24} />
            </ListItem>
          ) : (
            <>
              <Collapse in={listsOpen} timeout="auto" unmountOnExit>
                {contactGroupsQuery?.data
                  ?.filter(
                    (cg) =>
                      cg.school === school?.school &&
                      cg.text.slice(0, 4) !== 'all-'
                  )
                  .sort((a, b) =>
                    a.text !== b.text ? (a.text < b.text ? -1 : 1) : 0
                  )
                  .map((contactGroup) => {
                    return (
                      <ListsMenuItem
                        key={contactGroup.id}
                        list={contactGroup}
                        location={props.location}
                      />
                    );
                  })}
                <ListItem button onClick={handleAddListOpen}>
                  <ListItemIcon>
                    <AddIcon />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography variant="body2" noWrap>
                        Create List
                      </Typography>
                    }
                  />
                </ListItem>
              </Collapse>
              {addListOpen && (
                <AddList
                  open={addListOpen}
                  setOpen={setAddListOpen}
                />
              )}
            </>
          )}
        </List>
      )}
    </>
  );
};

export default ListsMenu;

const useStyles = makeStyles((theme) => ({
  counter: {
    marginLeft: theme.spacing(1),
  },
  menu: {
    '& .MuiListItemIcon-root': {
      minWidth: '40px',
    },
    '& .MuiListItem-gutters': {
      paddingLeft: theme.spacing(3),
    },
    '& .Mui-selected': {
      backgroundColor: `rgba(${theme.palette.primary.mainRGB}, 0.2)`,
      color: theme.palette.primary.dark,

      '& .MuiListItemIcon-root': {
        color: theme.palette.primary.dark,
      },
    },
  },
  loader: {
    justifyContent: 'center',
  },
}));
