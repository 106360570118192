import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
// import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { clearSnackbar } from '../redux/actions/uiActions';
// import { ActionCreators as UndoActionCreators } from 'redux-undo';

const InfoSnackbar = () => {
  const dispatch = useDispatch();
  const { infoSnackbarMessage, infoSnackbarOpen } = useSelector(
    (state) => state.ui
  );

  function handleClose() {
    dispatch(clearSnackbar());
  }

  // function handleUndo() {
  //   dispatch(UndoActionCreators.undo());
  //   handleClose();
  // }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={infoSnackbarOpen}
      autoHideDuration={4000}
      onClose={handleClose}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar">{infoSnackbarMessage}</span>
      }
      action={
        <>
          {/* {infoSnackbarUndo && (
            <Button
              color="secondary"
              size="small"
              onClick={handleUndo}
            >
              UNDO
            </Button>
          )} */}
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </>
      }
    />
  );
};

export default InfoSnackbar;
