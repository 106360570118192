import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListIcon from '@material-ui/icons/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import AddList from './AddList';
import DeleteListWithContacts from './DeleteListWithContacts';
import { showInfoSnackbar } from '../redux/actions/uiActions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useDeleteContactGroup from '../hooks/contactGroups/useDeleteContactGroup';

const ListsMenuItem = (props) => {
  const classes = useStyles();
  const { list, location } = props;
  // Add + Edit LIst
  const [addListOpen, setAddListOpen] = useState(false);

  // Delete List
  const [deleteListConfirmationOpen, setDeleteListConfirmationOpen] =
    useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const deleteContactGroup = useDeleteContactGroup();

  const handleAddListOpen = () => {
    setAddListOpen(true);
  };

  const handleDeleteList = () => {
    deleteContactGroup.mutate(list.id, {
      onSuccess: () => {
        dispatch(showInfoSnackbar('List deleted'));
        handleClose();
        if (location.includes(list.id)) {
          history.push('/contacts');
        }
      },
    });
  };

  const checkListForContacts = () => {
    if (!list.contacts.length) {
      handleDeleteList();
    } else {
      setDeleteListConfirmationOpen(true);
    }
  };

  const handleClose = () => {
    setDeleteListConfirmationOpen(false);
  };

  return (
    <div className={classes.root}>
      <ListItem
        button
        component={Link}
        to={`/list/${list.id}`}
        key={list.id}
        selected={location === `/list/${list.id}`}
      >
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={
            <Typography variant="body2" noWrap>
              {list.text}
            </Typography>
          }
        />
        {list.contacts && Object.keys(list.contacts)?.length > 0 && (
          <Typography
            variant="caption"
            component="small"
            className={classes.counter}
          >
            {Object.keys(list.contacts)?.length}
          </Typography>
        )}
        <ListItemSecondaryAction className={classes.edit}>
          <IconButton
            aria-label="edit"
            onClick={handleAddListOpen}
            edge="end"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={checkListForContacts}
            edge="end"
            className={classes.delete}
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <AddList
        open={addListOpen}
        setOpen={setAddListOpen}
        list={list}
      />
      <DeleteListWithContacts
        classes={{
          paper: classes.paper,
        }}
        list={list}
        id="delete-list-menu"
        open={deleteListConfirmationOpen}
        onClose={handleClose}
        handleDeleteList={handleDeleteList}
      />
    </div>
  );
};

export default ListsMenuItem;

const useStyles = makeStyles((theme) => ({
  root: {
    '& li .MuiListItem-secondaryAction': {
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      '& li .MuiListItem-secondaryAction': {
        paddingRight: theme.spacing(11),
      },
      '& li small': {
        display: 'none',
      },
    },
    [theme.breakpoints.up('sm')]: {
      '& li > div': {
        display: 'none',
      },
      '& li:hover small': {
        display: 'none',
      },
      '& li:hover .MuiListItem-secondaryAction': {
        paddingRight: theme.spacing(11),
      },
      '& li:hover > div': {
        display: 'inherit',
      },
    },
  },
  delete: {
    marginLeft: theme.spacing(1),
  },
}));
