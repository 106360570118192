import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import { showInfoSnackbar } from '../redux/actions/uiActions';
import Loading from '../components/Loading';
import UserAvatar from '../components/UserAvatar';
import { ListItemSecondaryAction } from '@material-ui/core';
import { useGetSchool } from '../hooks/contacts/useContactsQuery';
import useUpdateContactGroup from '../hooks/contactGroups/useUpdateContactGroup';
import TextField from '@material-ui/core/TextField';
import EmptyState from '../components/EmptyState';

const Teachers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [newTeacher, setNewTeacher] = React.useState('');

  // get contactGroup data for school
  const data = useGetSchool();

  const updateContactGroup = useUpdateContactGroup();

  const teachersArray = (data?.teachers || '') // Use empty string if data?.teachers is undefined
    .replace(/^"(.*)"$/, '$1') // Remove surrounding double quotes
    .split(', ') // Split by comma and space
    .filter(Boolean);

  const sortedTeachersArray = teachersArray?.sort((a, b) =>
    a.localeCompare(b)
  );

  const updateSchoolDetails = (updatedData) => {
    updateContactGroup.mutate(
      {
        contactGroup: {
          ...updatedData,
        },
      },
      {
        onSuccess: () => {
          dispatch(showInfoSnackbar('Saved'));
          //clear the new teacher field
          setNewTeacher('');
        },
      }
    );
  };

  const handleChange = (event) => {
    setNewTeacher(event.target.value);
  };

  const handleRemoveTeacher = (indexToRemove) => {
    const newArray = sortedTeachersArray.filter(
      (_, index) => index !== indexToRemove
    );

    const updatedTeachers = `"${newArray.join(', ')}"`;

    console.log(updatedTeachers);
    const updatedData = {
      ...data,
      teachers: updatedTeachers,
    };
    updateSchoolDetails(updatedData);
  };

  const handleAddTeacher = (teacher) => {
    // teachersArray.push(teacher);

    const newArray = sortedTeachersArray.concat(teacher);
    console.log(newArray);

    const updatedTeachers = `"${newArray.join(', ')}"`;
    console.log(updatedTeachers);

    const updatedData = {
      ...data,
      teachers: updatedTeachers, //needs to look like '"Barry, Jan"'
    };
    console.log(updatedData);
    updateSchoolDetails(updatedData);
  };

  if (!data) {
    return <Loading />;
  }

  return (
    <section className={classes.root}>
      <Box mt={2} ml={2} display="flex" alignItems="center">
        <TextField
          name="newTeacher"
          type="text"
          label="Add new teacher"
          value={newTeacher}
          fullWidth
          variant="outlined"
          onChange={handleChange}
        />
        <Box ml={2}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={() => {
              handleAddTeacher(newTeacher);
            }}
          >
            Add
          </Button>
        </Box>
      </Box>
      <List
        subheader={
          <ListSubheader component="div" id="list-subheader">
            Teachers ({sortedTeachersArray?.length})
          </ListSubheader>
        }
      >
        {!sortedTeachersArray?.length ? (
          <EmptyState text="No teachers available" icon="teacher" />
        ) : (
          sortedTeachersArray?.map((teacher, index) => (
            <div key={teacher} className={classes.listItem}>
              <ListItem button>
                <ListItemAvatar>
                  <UserAvatar label={`${teacher[0]}`} />
                </ListItemAvatar>
                <ListItemText primary={`${teacher}`} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleRemoveTeacher(index)}
                    className={classes.clearButton}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </div>
          ))
        )}
      </List>
    </section>
  );
};

export default Teachers;

const useStyles = makeStyles((theme) => ({
  listItem: {
    '& .MuiListItem-secondaryAction': {
      paddingRight: theme.spacing(14),
    },
    [theme.breakpoints.up('sm')]: {
      '& li .MuiListItemSecondaryAction-root': {
        display: 'none',
      },
      '& li:hover .MuiListItemSecondaryAction-root': {
        display: 'inherit',
      },
    },
  },
  actionButton: {
    marginLeft: 'auto',
  },
}));
