import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CalendarToday from '@material-ui/icons/CalendarToday';
import Person from '@material-ui/icons/Person';
import ImportContacts from '@material-ui/icons/ImportContacts';
import Dissatisfied from '@material-ui/icons/SentimentDissatisfiedOutlined';
import SupervisedUserIcon from '@material-ui/icons/SupervisedUserCircle';

const EmptyState = (props) => {
  const { text, icon } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {icon === 'calendarToday' && (
        <CalendarToday
          style={{ fontSize: 60 }}
          className={classes.icon}
        />
      )}
      {icon === 'person' && (
        <Person style={{ fontSize: 60 }} className={classes.icon} />
      )}
      {icon === 'teacher' && (
        <SupervisedUserIcon
          style={{ fontSize: 60 }}
          className={classes.icon}
        />
      )}
      {icon === 'import-contacts' && (
        <ImportContacts
          style={{ fontSize: 60 }}
          className={classes.icon}
        />
      )}
      {icon === 'not-found' && (
        <Dissatisfied
          style={{ fontSize: 60 }}
          className={classes.icon}
        />
      )}
      <Typography variant="h6" color="textSecondary">
        {text}
      </Typography>
    </div>
  );
};

export default EmptyState;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '240px',
  },
  icon: {
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));
