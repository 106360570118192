import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import EmptyState from '../components/EmptyState';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <>
      <EmptyState text="Page not found" icon="not-found" />
      <Box
        p={2}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Button
          component={Link}
          to="/"
          variant="contained"
          color="primary"
        >
          Go to home
        </Button>
      </Box>
    </>
  );
};

export default NotFound;
