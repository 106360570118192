import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import CalendarToday from '@material-ui/icons/CalendarToday';
import StatusIcon from '../StatusIcon';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { hasAdminRole } from '../utilities';

const BookingItem = (props) => {
  const classes = useStyles();
  const { id, status, startDate, notes, title, user } = props;

  return (
    <ListItem
      button
      to={
        hasAdminRole(user?.role)
          ? `../booking/${id}/edit`
          : `../ack-booking/${id}`
      }
      component={Link}
      key={id}
      className={classes.listItem}
    >
      <ListItemText
        disableTypography
        primary={
          <>
            <Typography
              variant="subtitle1"
              className={classes.listItemTitle}
              component="div"
            >
              {title}
            </Typography>
          </>
        }
        secondary={
          <>
            <Typography gutterBottom color="textSecondary">
              {moment(startDate).format('LLLL')}
            </Typography>
            {hasAdminRole(user?.role) && (
              <Box display="flex" alignItems="center" mt={1}>
                <Box mr={1} ml={0.25}>
                  <CalendarToday color="action" fontSize="small" />
                </Box>
                <Typography color="textSecondary">{notes}</Typography>
              </Box>
            )}
            <Box display="flex" alignItems="center" mt={1}>
              <Box mr={1}>
                <StatusIcon status={status} />
              </Box>
              <span className={classes.caption}>
                Booking No. {id}
              </span>
            </Box>
          </>
        }
      />
    </ListItem>
  );
};

export default BookingItem;

const useStyles = makeStyles((theme) => ({
  listItem: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius * 2,
    marginBottom: theme.spacing(2),
  },
  listItemTitle: {
    '&::first-letter': {
      textTransform: 'capitalize',
    },
  },
  caption: {
    fontWeight: theme.typography.fontWeightRegular,
  },
}));
