export function moveItem(arr, index, direction) {
  let newArr = [...arr];
  let item = newArr.splice(index, 1)[0];
  newArr.splice(direction === 'up' ? index - 1 : index + 1, 0, item);
  return newArr;
}

export function removeItem(array, index) {
  let newArray = array.slice();
  newArray.splice(index, 1);
  return newArray;
}

export function removeDuplicatesBy(keyFn, array) {
  var mySet = new Set();
  return array.filter(function (x) {
    var key = keyFn(x),
      isNew = !mySet.has(key);
    if (isNew) mySet.add(key);
    return isNew;
  });
}

export function toCapitalised(str) {
  return str
    .toLowerCase()
    .replace(/-/g, ' ')
    .split(' ')
    .map(function (word) {
      return word[0].toUpperCase() + word.substr(1);
    })
    .join(' ');
}

export const weekDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

// Utility function to check if the user has either 'admin' or 'superAdmin' role
export const hasAdminRole = (role) =>
  role === 'admin' || role === 'superAdmin';
