import React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

const DeleteListWithContacts = (props) => {
  const {
    list,
    onClose,
    value: valueProp,
    open,
    handleDeleteList,
    ...other
  } = props;

  const handleCancel = () => {
    onClose();
  };

  const contactListLength = list?.contacts ? list.contacts.length : 0;

  return (
    <Dialog
      maxWidth="sm"
      aria-labelledby="delete-list-dialog"
      open={open}
      fullWidth
      {...other}
    >
      <DialogTitle id="delete-list-dialog">Delete List</DialogTitle>
      <DialogContent>
        <DialogContentText id="elete-list-dialog-description">
          {`This list contains ${contactListLength} contact${
            contactListLength > 1 ? 's' : ''
          }. The contacts will not be deleted, only the list. 
          Are you sure you wish to continue?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDeleteList} color="primary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteListWithContacts;
