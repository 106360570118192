import { useQueryClient, useQuery } from 'react-query';
import { useTokenQuery } from '../useTokenQuery';

// Function to fetch bookings for a given date range
const fetchBookingsForPeriod = async (start, end, token) => {
  let apiUrl = process.env.REACT_APP_API_URI.trim();

  const response = await fetch(
    `${apiUrl}/bookings?startBooking=${start}&endBooking=${end}`,
    {
      method: 'get',
      headers: new Headers({
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      }),
    }
  );

  if (!response.ok) {
    throw new Error('Failed to fetch bookings');
  }

  return response.json();
};

// Custom hook to fetch bookings for a period
export function useBookingsForPeriodQuery(start, end) {
  const tokenQuery = useTokenQuery();
  const queryClient = useQueryClient();

  // Fetch bookings for the period using React Query
  const { data: bookingsForPeriod } = useQuery(
    ['bookings_for_period', start, end],
    () => fetchBookingsForPeriod(start, end, tokenQuery.data),
    {
      enabled: !!tokenQuery.data,
    }
  );

  // Function to refetch bookings
  const refetchBookings = async () => {
    try {
      await queryClient.invalidateQueries([
        'bookings_for_period',
        start,
        end,
      ]);
    } catch (error) {
      console.error('Error refetching bookings:', error);
    }
  };

  return { bookingsForPeriod, refetchBookings };
}
