import { useMutation, useQueryClient } from 'react-query';
import { useTokenQuery } from '../useTokenQuery';

export default function useCreateBookingGroup() {
  const tokenQuery = useTokenQuery();
  const queryClient = useQueryClient();

  return useMutation(
    'booking_create',
    (booking) => {
      let apiUrl = process.env.REACT_APP_API_URI.trim();

      console.log(booking);

      return fetch(`${apiUrl}/bookings`, {
        method: 'post',
        headers: new Headers({
          Authorization: 'Bearer ' + tokenQuery.data,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(booking),
      }).then((res) => {
        queryClient.invalidateQueries(['booking', booking.id]);
        queryClient.invalidateQueries('bookings');
        return res.json();
      });
    },
    {
      enabled: !!tokenQuery.data,
    }
  );
}
