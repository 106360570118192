import { useQuery, useQueryClient } from 'react-query';
import { useTokenQuery } from '../useTokenQuery';
import { useMsal } from '@azure/msal-react';
import { useContactGroupsQuery } from '../contactGroups/useContactGroupsQuery';

export function useContactsQuery() {
  const tokenQuery = useTokenQuery();

  return useQuery(
    'contacts',
    () => {
      let apiUrl = process.env.REACT_APP_API_URI.trim();

      return fetch(`${apiUrl}/contacts/`, {
        method: 'get',
        headers: new Headers({
          Authorization: 'Bearer ' + tokenQuery.data,
          'Content-Type': 'application/json',
        }),
      }).then((res) => res.json());
    },
    {
      enabled: !!tokenQuery.data,
    }
  );
}

export function useContactQuery(id) {
  const tokenQuery = useTokenQuery();
  const queryClient = useQueryClient();

  return useQuery(
    ['contacts', id],
    () => {
      let apiUrl = process.env.REACT_APP_API_URI.trim();

      return fetch(`${apiUrl}/contacts/${id}`, {
        method: 'get',
        headers: new Headers({
          Authorization: 'Bearer ' + tokenQuery.data,
          'Content-Type': 'application/json',
        }),
      }).then((res) => res.json());
    },
    {
      enabled: !!tokenQuery.data,
      staleTime: 5 * 60 * 1000, // 2 minutes
      // initially populate the booking from the parent bookings query
      initialData: () =>
        queryClient
          .getQueryData('contacts')
          ?.find((contact) => contact.id === id),
    }
  );
}

export function useGetSchool() {
  const { accounts } = useMsal();
  const user = accounts[0]?.username;
  const contactGroupsQuery = useContactGroupsQuery();

  // Find a base school that begin with 'all-'
  // Find the one the user is on
  return contactGroupsQuery.data
    ? contactGroupsQuery?.data?.find(
        (cg) =>
          cg.text.includes('all-') &&
          cg.contacts.find((c) => c.id === user)
      )
    : contactGroupsQuery;
}

// Admin only function
// Create a hook that returns all schools i.e text.includes('all-')
export function useGetSchools() {
  const contactGroupsQuery = useContactGroupsQuery();

  // Find a base school that begin with 'all-'
  // Find the one the user is on
  return contactGroupsQuery.data
    ? contactGroupsQuery?.data?.filter((cg) =>
        cg.text.includes('all-')
      )
    : contactGroupsQuery;
}
